.head-back {
    display: flex;
    width: 100%;
    height: 10vw;
    background-color: rgba(34, 150, 132, 0.15);
    color: #229684;
    font-size: 1.5em;
    padding: 20px 0px 7px 10px;
}

.head-back>.icon-back {
    padding-right: 10px;
}

.head-back>.title-back::before {
    content: "";
    z-index: 0;
    position: absolute;
    display: block;
    top: -7px;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 1px;
    height: 35%;
    background-color: #229684;
}

.head-back>.title-back {
    padding-left: 12px;
    position: relative;
    font-size: 0.9em;
}

.head-back>.back-img {
    position: relative;
    /* bottom: 5px; */
}

.head-back>.back-img img {
    width: auto;
    height: 120px;
}

.head-back>.content-booking {
    z-index: 1;
    position: relative;
    /* width: 100%; */
    width: 70%;
    bottom: 20px;
    margin-left: auto;
    
}

.head-back>.content-booking>.booking-head {
    display: flex;
    justify-content: center;
}

.head-back>.content-booking .booking-title {
    display: block;
    margin: 0 auto;
    padding: 0.25em;
    min-width: 60%;
    text-align: center;
    font-size: 2.5vw;
    color: #239684;
    border-top: 1px solid #239684;
    border-bottom: 1px solid #239684;
}

.head-back>.content-booking .booking-slider>.slick-dots {
    bottom: 0;
    right: 0;
    display: flex !important;
    width: auto;
    padding: 1.5vw;
}

.head-back>.content-booking .booking-slider>.slick-dots>li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.head-back>.content-booking .booking-slider>.slick-dots>li>button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s;
}

.head-back>.content-booking .booking-slider>.slick-dots>li>button::before {
    content: none;
}

.head-back>.content-booking .booking-slider>.slick-dots>li:hover>button {
    opacity: 0.5;
}

.head-back>.content-booking .booking-slider>.slick-dots>li.slick-active>button {
    opacity: 1;
}

.head-back>.content-booking .booking-link {
    position: relative;
    display: block;
    height: 10vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.head-back>.content-booking .booking-link::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity .3s;
}

.head-back>.content-booking .booking-link:hover::after {
    opacity: 0.3;
    cursor: pointer;
}

@media(max-width: 1200px) {
    .head-back>.back-img {
        bottom: 18px;
    }
    .head-back>.content-booking {
        z-index: 1;
        position: relative;
        width: 67%;
    }
}

@media(max-width: 992px) {
    .head-back {
        height: 13vw;
    }
    .head-back>.back-img {
        bottom: 15px;
    }
    .head-back>.content-booking .booking-link {
        height: 13vw;
    }
}

@media(max-width: 769px) {
    .head-back {
        height: 15vw;
    }
    .head-back>.back-img {
        bottom: 3px;
    }
    .head-back>.back-img img {
        height: 80px;
    }
    .head-back>.content-booking {
        width: 73%;
    }
    .head-back>.content-booking .booking-link {
        height: 15vw;
    }
}

@media(max-width: 585px) {
    .head-back>.title-back::before {
        content: "";
        z-index: 0;
        position: absolute;
        display: block;
        top: -7px;
        bottom: 20px;
        left: 0;
        right: 0;
        width: 1px;
        height: 90%;
        background-color: #229684;
    }
    .head-back>.back-img {
        bottom: 15px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}

@media(max-width: 510px) {
    .head-back>.back-img {
        bottom: 21px;
    }
}

@media(max-width: 415px) {
    .head-back>.title-back {
        font-size: 0.7em;
    }
    .head-back>.back-img {
        bottom: 12px;
    }
    .head-back>.back-img img {
        height: 50px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}

@media(max-width: 376px) {
    .head-back {
        margin-top: 9px;
    }
    .head-back>.back-img {
        bottom: 6px;
    }
    .head-back>.back-img img {
        height: 40px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}


.ibox {
    display: flex;
    padding: 0 3vw;
    align-items: center;
}

.ibox>.ibox-thumb {
    display: block;
    text-align: center;
}

.ibox>.ibox-info {
    display: block;
    padding: 15px 25px;
    flex-basis: 100%;
    overflow: hidden;
}

.ibox .box-cover {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #d4d9e2;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: background-size .3s;
}

.ibox .box-cover:hover {
    background-size: cover;
}

.ibox .box-title {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4;
    overflow: hidden;
    margin-bottom: 0.5em;
    color: #346460;
}

.ibox .box-detail {
    display: block;
    font-size: 1em;
    margin-bottom: 0.6em;
}

.ibox .box-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    transition: all .3s;
    color: #229684;
    font-size: 0.75em;
}

.ibox:hover .box-links {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%);
}

.ibox .box-links>li {
    padding: 5px 0;
    padding-right: 10px;
}

.ibox .box-links>li+li {
    padding-left: 10px;
    border-left: 1px solid #229684;
}

.ibox .box-links a {
    display: block;
    line-height: 1;
    color: #2078b0;
    text-decoration: none;
}

.ibox .box-links a:hover {
    color: #5c6773;
}

.ibox .box-tags{
    margin-top: 20px;
}

@media (max-width:600px) {
    .ibox {
        display: block;
    }
    .ibox>.ibox-info {
        padding: 0 0 10px;
    }
    .ibox .box-cover {
        display: inline-block;
    }
    .ibox .box-title {
        -webkit-line-clamp: unset;
        height: auto;
        font-size: 1.1em;
    }
    .ibox .box-links {
        margin-top: 10px;
    }
}


/* list */

.ibox-list {
    display: block;
    align-items: center;
}

.ibox-list>.ibox-img {
    display: block;
    text-align: center;
}

.ibox-list>.ibox-info {
    display: block;
    padding: 15px 0px;
    flex-basis: 100%;
    overflow: hidden;
}

.ibox-list .box-cover {
    display: block;
    width: 252px;
    height: 157px;
    background-color: #bbbbbb;
    /* background-image: url(../Images/home/example/news/news1.jpg); */
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #d4d9e2;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: background-size .3s;
}

.ibox-list .box-cover:hover {
    background-size: 120%;
}

.ibox-list .box-title {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    overflow: hidden;
    margin-bottom: 0.5em;
    color: #3da393;
}

.ibox-list .box-detail {
    display: block;
    font-size: 1em;
    margin-bottom: 0.6em;
}

.ibox-list .box-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    transition: all .3s;
    font-weight: bold;
    font-size: 0.75em;
}

.ibox-list:hover .box-links {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%);
}

.ibox-list .box-links>li {
    padding: 5px 0;
    padding-right: 10px;
}

.ibox-list .box-links>li+li {
    padding-left: 10px;
    /* border-left: 1px solid #229684; */
}

.ibox-list .box-links a {
    display: block;
    line-height: 1;
    color: #2078b0;
    text-decoration: none;
}

.ibox-list .box-links a:hover {
    color: #5c6773;
}

.ibox-list .box-tags{
    margin-top: 20px;
}

@media (max-width:600px) {
    .ibox-list {
        display: block;
    }
    .ibox-list>.ibox-info {
        padding: 0 0 10px;
        /* text-align: center; */
    }
    .ibox-list .box-cover {
        display: inline-block;
    }
    .ibox-list .box-title {
        -webkit-line-clamp: unset;
        height: auto;
        font-size: 1.1em;
    }
    .ibox-list .box-links {
        margin-top: 10px;
    }
}

/* panel */
#PAGE_HOME>.home-news>.news-panel>.panel-item {
    display: flex;
    margin-top: 1.5vw;
    height: 35vw;
    background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5vw;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .left {
    flex-basis: 40%;
    max-width: 40%;
    position: relative;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item-content {
    margin-top: 1.5vw;
}


#PAGE_HOME > .home-news > .news-panel > .panel-item-content > .news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent .news-item {
    cursor: pointer;
    display: block;
    flex-basis: 23%;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item  .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item  .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item  .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .news-item .news-date, #PAGE_HOME>.home-news>.news-panel>.panel-item-transparent >.news-item .news-date {
    z-index: 2;
    position: absolute;
    bottom: 4%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item:hover::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item:hover::after {
    opacity: 0.25;
} */

#PAGE_HOME > .home-news > .news-panel > .panel-item  >  .news-item.news-primary, 
#PAGE_HOME > .home-news > .news-panel > .panel-item  >  .news-item.news-primary-x, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary {
    flex-basis: 40%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary > .news-cover, 
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x > .news-cover, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary > .news-title,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x > .news-title,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary .news-date,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x .news-date,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary   .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary:hover::before,
/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x:hover::before, */
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary:hover::after,
/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x:hover::after, */
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item:last-of-type {
    margin-right: 1%;
}

.ebook-popup {
    top: 0%;
}

.ebook-popup > .ant-modal-content {
    background-color: transparent !important;
    box-shadow: 0px 0px;
    top: 0%;
}

.ebook-popup > .ant-modal-content > .ant-modal-close {
    margin-top: 3em;
    margin-right: 2em;
}

/* .ebook-full >  .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
   } */

/* .ant-modal-body {
height: calc(100vh - 110px);
} */

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 38vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        flex-basis: 44%;
        height: 15vw;
        margin: 0 0 29px 30px;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-news {
        padding: 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
}

/* secondary */

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-basis: 60%;
    max-width: 60%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider{
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
    bottom: -7px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li {
    margin: 0 3px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li button:before {
    font-size: 10px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #37a08f;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .right-item {
    display: block;
    min-width: 50%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-prev, #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-next {
    top: calc(22.5vw * 3 / 4 * 0.5);
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .scroll-panel{
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .scroll-panel .scrollarea-content {
    display: flex;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item {
    position: relative;
    /* flex-basis: calc(100% / 2);
    max-width: calc(100% / 2); */
    display: flex;
    flex-direction: column;
    min-width: 45%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .secondary-item .news-date, #PAGE_HOME>.home-news>.news-panel>.panel-item-transparent .secondary-item .news-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:hover::after,
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:hover::after {
    opacity: 0.25;
} */

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
    /* flex-basis: 45%; */
    height: 14vw;
    margin: 0 0 15px 30px;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary  .news-cover,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x  .news-cover,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary  .news-title,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x  .news-title,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary  .news-date,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x  .news-date,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary  .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary:hover::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x:hover::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary:hover::after,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x:hover::after,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:nth-of-type(2) {
    /* margin-left: 1%; */
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:last-of-type {
    /* margin-right: 1%; */
}

.flowpaper_bttnFullscreen{
    display: none;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        flex-basis: 44%;
        height: 15vw;
        margin: 0 0 29px 30px;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
        bottom: -5px;
    }

    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 769px) {
    #PAGE_HOME>.home-news {
        padding: 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 70vw;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-item > .left {
        flex-basis: 100%;
        max-width: 100%;
        position: relative;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right {
        flex-basis: 100%;
        min-width: 100%;
        position: relative;
        margin-top: 10px;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item.news-primary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item.news-primary {
        height: 20vw;
    }



    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
        bottom: -10px;
    }
    
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  .secondary-item.news-secondary {
        flex-basis: 60%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
}

@media(max-width: 576px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 75vw;
    }
}
@media(max-width: 415px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 90vw;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        font-size: 0.75em;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary .news-title,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x .news-title,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-title {
        top: 10%;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        height: 20vw;
    }
}

@media(max-width: 376px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 95vw;
    }
}

/* knowledge */
#KNOWLEDGE {
    background-color: #ffffff;
    background-position-x: top;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
@media (max-width: 1199px) {
    #KNOWLEDGE {
        padding: 5vh 15px;
    }
}

.iknowledge {
    display: flex;
    padding: 15px 0;
    width: 100%;
}

.iknowledge+.iknowledge {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.iknowledge>.iknowledge-info {
    display: block;
    padding: 6px 0px;
    flex-basis: 100%;
    overflow: hidden;
}

.iknowledge .knowledge-flag {
    display: inline-block;
    margin-bottom: 0px;
    padding: 0px 20px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    color: #346460;
}

.iknowledge .knowledge-level {
    display: block;
    padding: 5px 20px;
    font-size: 1.2em;
    line-height: 1;
    color: #2e95ff;
}

.iknowledge .knowledge-title {
    display: block;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1;
}

.iknowledge .knowledge-desc {
    display: block;
    margin-top: 10px;
    line-height: 1.1;
}

.iknowledge .knowledge-tags {
    display: block;
    margin-top: 5px;
    line-height: 1;
}
.iknowledge .knowledge-tags .knowledge-ex {
    padding: 7px 20px;
    display: grid;
}
.iknowledge .knowledge-flag+.knowledge-tags {
    margin-top: 15px;
}
.head-title {
    font-weight: 500;
}
.text-title {
    color: #249d8a;
    font-size: 1.2em;
    margin-top: 3px;
}

@media (max-width:575px) {
    .iknowledge {
        display: block;
    }
    .iknowledge>.iknowledge-info {
        padding: 20px 0;
        text-align: center;
    }
    .iknowledge .knowledge-title {
        font-size: 1.1em;
    }
    .iknowledge .knowledge-links {
        margin-left: 0;
        display: inline-flex;
    }
    .iknowledge .knowledge-tags,
    .iknowledge .knowledge-links {
        margin-top: 15px;
    }

    .iknowledge .knowledge-tags .knowledge-interview {
        display: inline-block;
    }
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
    display: block;
    position: absolute;
    width: 100%;
    bottom: -5px;
    margin: 0 25px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-1 {
    display: inline-block;
    margin: 1px;
    text-align: left;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
    position: relative;
    display: inline-block;
    margin: 1px;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    left: 87%;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
    position: relative;
    display: inline-block;
    margin: 1px;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    left: 97%;
    cursor: pointer;
}

@media(max-width: 768px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
        bottom: 0px;
        margin: 0px;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 84%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 93%;
    }
}

@media(max-width: 576px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 89%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 91%;
    }
}

@media(max-width: 415px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 70%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 87%;
    }
}

@media(max-width: 376px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
        margin: 0px;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 67%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 86%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .ant-pagination-item {
        min-width: 30px;
        height: 30px;
    }
}
/* Provide sufficient contrast against white background */

@import url('Styles/Fonts/Prompt/css/font.css');
body {
    margin: 0 auto;
    font-family: Prompt, Tahoma !important;
    font-size: 16px;
    line-height: 1.1;
}

a {
    color: #0366d6;
    text-decoration: none !important;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.ant-collapse-header {
    background-color: #027DB4;
    color: #ffffff !important;
}

.ant-input[disabled] {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.ant-picker-input>input[disabled] {
    color: rgba(0, 0, 0, 0.85);
}

.ant-picker.ant-picker-disabled {
    cursor: not-allowed;
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
}

textarea.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: #f5f5f5;
}

.ant-radio-disabled+span {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
}

.ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
}

.form-search {
    position: relative;
    width: 100% !important;
}

.form-search::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    padding: 0.375rem 0.75rem;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.form-search .form-control {
    padding-right: 3.25em;
}

tr.ant-table-row.ant-table-row-level-0 .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    display: none;
}

.react-calendar {
    width: 100% !important;
    min-width: 310px !important;
    max-width: 100%;
    font-family: inherit !important;
    border: 1px solid #ced4da !important;
}

.ant-input-affix-wrapper-disabled {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.react-calendar__navigation {
    height: 40px !important;
}

.react-calendar__navigation button {
    min-width: 40px !important;
}

.react-calendar__navigation__label {
    font-weight: bolder;
}

.react-calendar__month-view__weekdays {
    font-size: 1em !important;
}

.react-calendar abbr[title],
.react-calendar abbr[data-original-title] {
    text-decoration: none;
}

.fileuploader,
.fileuploader-popup {
    font-family: inherit;
}

.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-meta h5 {
    color: #ffffff;
}

.fileuploader-items .fileuploader-item .columns {
    align-items: center;
}

.fileuploader-disabled .fileuploader-input {
    opacity: .7;
    cursor: default;
    pointer-events: none !important;
    display: none;
}

div.fileuploader-input-caption form-control {
    color: red;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    color: #343a40;
    border-radius: 2px;
    margin-right: 1px;
}

.block-ui-overlay {
    opacity: 0.7 !important;
    background-color: #000000 !important;
}

.loading-bullet {
    color: #ffffff !important;
}

@media (min-width: 1300px) {
    .container {
        max-width: 1280px;
    }
}

.block-ui-container {
    position: fixed !important;
}

.ant-picker-content {
    height: 112px !important;
}

.ant-picker-time-panel-column::after {
    height: 0px;
}

.ant-table-small .ant-table-thead>tr>th {
    background-color: #e6ffe5;
}

.ant-table-small .ant-table-thead>tr>th:hover,
.ant-table-small .ant-table-thead>tr>th:focus {
    background-color: #e6ffe5;
}

.ant-table-column-sorters .ant-table-column-sorter {
    color: #000000;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #999999;
}

.ant-table-thead>tr>th {
    text-align: center !important;
}

.ant-switch {
    background-color: #cc2838;
}

.ant-switch-checked {
    background-color: #60cc28;
}

.ant-input {
    border-radius: 1em;
}

.ant-input-affix-wrapper {
    border-radius: 1em;
}

.ant-input-affix-wrapper>.ant-input {
    border-radius: 0;
}

.ant-select-selector {
    border-radius: 1em !important;
}

.ant-pagination-item-link,
.ant-pagination-item {
    border-radius: 1em !important;
}

.ant-pagination-item.active {
    font-weight: 500;
    background: #fff;
    border-color: #255058;
}

.ant-pagination-item:hover {
    color: #255058;
    border-color: #255058;
}

.ant-pagination-item > .ant-pagination-item-link:hover {
    color: #255058;
}

div#canvasLoader {
    margin-left: -3.5%;
    margin-top: 3.5%;
}

@import url(//fonts.googleapis.com/css?family=Roboto:400,300,300italic,400italic,700,700italic&subset=latin,vietnamese,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek);

@import url(//fonts.googleapis.com/css?family=Oswald:400,300,700&subset=latin,latin-ext);

@import url(//fonts.googleapis.com/css?family=Montserrat:400,700);

@import url(//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700&subset=latin,greek,greek-ext,vietnamese,cyrillic-ext,cyrillic,latin-ext);
@import url('https://fonts.googleapis.com/css2?family=Charmonman&family=Chonburi&family=Mitr:wght@200&family=Pattaya&family=Prompt:wght@100;300&family=Sriracha&family=Srisakdi&display=swap');
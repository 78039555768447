#PAGE_INTRO {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#PAGE_INTRO>.intro-cover {
    flex-grow: 1;
    display: block;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_INTRO>.intro-bar {
    flex-basis: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4cb0a1;
    border-top: 5px solid #ffffff;
}

#PAGE_INTRO>.intro-bar>a {
    display: block;
    padding: 0.5em 2em;
    margin: 10px 20px;
    text-transform: uppercase;
    color: #234d1f;
    background-color: #ffffff;
    border-radius: 2em;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    transition: color .3s, background-color .3s;
    width: 200px;
    text-align: center;
}

#PAGE_INTRO>.intro-bar>a:hover {
    color: #ffffff;
    background-color: #94b796;
}
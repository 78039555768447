#REPORT .Summary_Complaint_Report .ant-table-summary {
    font-weight: 500;
}

#REPORT .Summary_Complaint_Report .ant-table-thead > tr > th {
    text-align: center
}

#REPORT .ant-total {
    position: sticky;
    left: 0;
}



/*data*/
/*group1*/
/*#REPORT .Summary_Complaint_Report td.summary-in-group-1, th.cell-in-group-month-1 {
    background-color: #bae7ff !important;
}
*/




/*-----------*/





/*first column*/
#REPORT .ant-table-summary .ant-total {
    background-color: #e6ffe5 !important;
    font-weight: 500;
    border-bottom: 1px solid #000000;
}

#REPORT .ant-table-tbody .ant-table-cell-fix-left-last {
    background-color: #eaeaea !important;
}

#REPORT .ant-impress-the-website {
    background-color: red !important;
}


#REPORT .ant-table-summary .summary-in-group-1 {
    background-color: #bae7ff !important;
    border-bottom: 1px solid #000000;
}

#REPORT .ant-table-summary .summary-in-group-2 {
    background-color: #bae7ff !important;
    border-bottom: 1px solid #000000;
}
/*-----------*/

#REPORT td {
    border: 1px solid #000000;
    padding: 8px;
}

#REPORT th {
    border: 1px solid #000000;
    padding: 8px;
}

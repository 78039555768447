
#MAIN_MAP .list-province:hover {
    background-color: aliceblue;
    cursor: pointer;
}


#MAIN_MAP .scroll-data {
    display: block;
    height: 300px;
    position: relative;
}


    #MAIN_MAP .scroll-data .scroll-panel {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


#MAIN_MAP .box {
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    border: 1px solid black;
    border-radius: 10px;
}

#MAIN_MAP .new {
    background-color: #3ae4bb;
}

#MAIN_MAP .old {
    background-color: #ee9852;
}

#MAIN_MAP .other {
    background-color: #f96993;
}


#MAIN_MAP .esri-view-width-less-than-medium .esri-popup__main-container {
    width: 500px;
}




#MAIN_MAP .esri-view-height-less-than-medium .esri-popup__main-container {
    max-height: 300px;
}

#MAIN_MAP .map-list {
    border-radius: 20px;
    background-color: transparent;
}

    #MAIN_MAP .map-list .ant-list-item.list-province {
        border-radius: 20px;
        background-color: #effff4;
    }

        #MAIN_MAP .map-list .ant-list-item.list-province + .ant-list-item.list-province {
            margin: 12px 0;
        }

        #MAIN_MAP .map-list .ant-list-item.list-province:hover {
            background-color: #daece0;
        }



@media screen and (max-width: 1024px) {
    .mapcontainer {
        height: 300px !important;
    }
}


/*@media(max-width: 575px) {
    #Cookies_Policy .policy {
        min-height: calc(100vw * 15 / 6);
    }
}*/


/*#MAIN_MAP .icon-type1 {

    filter: hue-rotate(120deg);
}
*/

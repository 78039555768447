#SITE_CONTENT {
    z-index: 0;
    position: relative;
    display: block;
    min-height: 100vh;
    padding-top: 60px;
}

    #SITE_CONTENT > .s-header {
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        padding: 0 15px;
        height: 80px;
        color: #ffffff;
        background-color: #255058;
    }

        #SITE_CONTENT > .s-header > .header-title {
            display: flex;
            align-items: center;
            margin-right: auto;
        }

            #SITE_CONTENT > .s-header > .header-title > .title-icon {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 10px;
                border-right: 1px solid #ffffff;
            }

                #SITE_CONTENT > .s-header > .header-title > .title-icon > img {
                    height: 40px;
                }

            #SITE_CONTENT > .s-header > .header-title > .title-label {
                padding-left: 10px;
                color: #ffffff;
            }

                #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
                    font-weight: 500;
                    font-size: 1.45em;
                }

                #SITE_CONTENT > .s-header > .header-title > .title-label > .label-2 {
                    font-weight: 300;
                    font-size: 1.1em;
                }

        #SITE_CONTENT > .s-header > .header-content {
            display: flex;
            flex-direction: column;
        }

        #SITE_CONTENT > .s-header > .header-search {
            position: absolute;
            top: 100%;
            /* left: 0; */
            right: 0;
            display: block;
            padding: 1em 0;
            background-color: rgba(150, 180, 150, 0.75);
            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            transition: padding .3s, height .3s;
            width: 45%;
        }

            #SITE_CONTENT > .s-header > .header-search.collapsed {
                padding: 0;
                height: 0;
                overflow: hidden;
            }

            #SITE_CONTENT > .s-header > .header-search .ant-input {
                background-color: rgba(255, 255, 255, 0.75);
            }

        #SITE_CONTENT > .s-header .header-contact {
            display: flex;
            justify-content: end;
            align-items: center;
            height: 35px;
        }

            #SITE_CONTENT > .s-header .header-contact > .contact-us {
                display: none;
            }

            #SITE_CONTENT > .s-header .header-contact > .contact-item {
                padding: 0.15em 0.5em;
            }

                #SITE_CONTENT > .s-header .header-contact > .contact-item + .contact-item {
                    border-left: 1px solid #ffffff;
                    margin-left: 15px;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon {
                    display: inline-block;
                    flex-basis: 1.85em;
                    min-width: 1.85em;
                    width: 1.85em;
                    height: 1.85em;
                    line-height: 1.85em;
                    font-size: 0.8em;
                    text-align: center;
                    color: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    margin: 0 4px;
                }

                    #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon > i {
                        line-height: inherit;
                    }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .facebook.contact-item-icon:hover {
                    background: #1877f2;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .line.contact-item-icon:hover {
                    background: #00d145;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .ins.contact-item-icon:hover {
                    background: linear-gradient(to right top, #fccc63, #fcb758, #fba250, #f88d4d, #f3774d, #f06752, #eb5757, #e5475e, #df3b6a, #d63175, #cb2b81, #bc2a8d);
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .youtube.contact-item-icon:hover {
                    background: #ff0000;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .facebook.contact-item-icon.active {
                    background: #1877f2;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .line.contact-item-icon.active {
                    background: #00d145;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .ins.contact-item-icon.active {
                    background: linear-gradient(to right top, #fccc63, #fcb758, #fba250, #f88d4d, #f3774d, #f06752, #eb5757, #e5475e, #df3b6a, #d63175, #cb2b81, #bc2a8d);
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .youtube.contact-item-icon.active {
                    background: #ff0000;
                    border: none;
                }

            #SITE_CONTENT > .s-header .header-contact .conteact-panel {
                position: absolute;
                top: 100%;
                /*   right: 20%;*/
                left: 0;
                display: block;
                padding: 1em 0;
                background-color: rgba(0, 0, 0, 0.6);
                transition: padding .3s, height .3s;
                width: 350px;
                z-index: 10;
                margin-top: 0.2em;
                width: max-content;
            }

                #SITE_CONTENT > .s-header .header-contact .conteact-panel.panel {
                    padding: 0;
                    height: 0;
                    overflow: hidden;
                }

                #SITE_CONTENT > .s-header .header-contact .conteact-panel a:hover {
                    color: #84ee9b;
                }

        #SITE_CONTENT > .s-header .language-toggler {
            color: inherit;
            font-size: 0.9em;
        }

            #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper {
                height: auto;
                padding: 2px 7px;
                line-height: 1;
                color: inherit;
                background-color: transparent;
                border-color: #ffffff;
                border-width: 0;
            }

                #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper:not(:first-child)::before {
                    background-color: #ffffff;
                }

                #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
                    color: #84a94a;
                }

        #SITE_CONTENT > .s-header .header-nav {
            display: flex;
            justify-content: end;
            margin: 0 -5px;
        }

            #SITE_CONTENT > .s-header .header-nav > .nav-col {
                display: block;
                padding: 0 5px;
            }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin: 0;
            padding: 0 1em;
            height: 1.825em;
            background: #94b796;
            background: -moz-linear-gradient(left, #94b796 35%, #58827b 100%);
            background: -webkit-linear-gradient(left, #94b796 35%, #58827b 100%);
            background: linear-gradient(to right, #94b796 35%, #58827b 100%);
            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#94b796', endColorstr='#58827b', GradientType=1);
            border-radius: 2em;
        }

        #SITE_CONTENT > .s-header .menu > li {
            position: relative;
            height: 100%;
        }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) > li + li {
            margin-left: 1em;
        }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) > li:hover::before {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1em;
            right: -1em;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2em;
        }

        #SITE_CONTENT > .s-header .menu a {
            z-index: 1;
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            color: #ffffff;
            font-size: 0.9em;
        }

            #SITE_CONTENT > .s-header .menu a > .link-icon {
                margin-right: 5px;
                width: 1em;
            }

                #SITE_CONTENT > .s-header .menu a > .link-icon > img {
                    max-width: 1em;
                    max-height: 1em;
                }

            #SITE_CONTENT > .s-header .menu a > .link-label {
                flex-basis: 100%;
            }

            #SITE_CONTENT > .s-header .menu a > .link-caret {
                padding-left: 5px;
            }

            #SITE_CONTENT > .s-header .menu a.active {
                color: #255058;
                font-weight: 600;
            }

        #SITE_CONTENT > .s-header .nav-btn {
            display: inline-block;
            padding: 0.35em 1em;
            border-width: 0;
            border-radius: 2em;
            outline: none;
        }

        #SITE_CONTENT > .s-header .btn-default {
            color: #255058;
            background-color: #ffffff;
        }

        #SITE_CONTENT > .s-header .btn-search {
            color: #ffffff;
            background-color: #84a94a;
        }

    #SITE_CONTENT > .s-body {
        position: relative;
        min-height: calc(100vh - 80px);
        background-color: #ffffff;
        background-image: url(../../Images/home/bg-main.png);
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }

    #SITE_CONTENT > .s-footer {
        position: relative;
        display: block;
    }

        #SITE_CONTENT > .s-footer > .footer-subscribe {
            display: block;
            padding: 1.5em 0;
            background-color: #89a9ac;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap {
            display: block;
        }

            #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap > .content-private > .private-title {
                    color: #ffffff;
                    margin-right: 10px;
                }

                #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap > .content-private > .private-desc {
                    color: #ffffff;
                    padding-left: 10px;
                    border-left: 1px solid rgba(255, 255, 255, 1);
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: flex-end;
                padding: 0.75em 5vw;
                color: #ffffff;
                background-color: #255058;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header:hover {
                    background-color: #1a6b5e;
                }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header .ant-collapse-arrow {
                    margin-left: 7.5em;
                    margin-right: 0;
                    position: absolute;
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-content {
                background: #219583;
                background: -moz-linear-gradient(left, #219583 0%, #50b6a6 100%);
                background: -webkit-linear-gradient(left, #219583 0%, #50b6a6 100%);
                background: linear-gradient(to right, #219583 0%, #50b6a6 100%);
                filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#219583', endColorstr='#50b6a6', GradientType=1);
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-content > .ant-collapse-content-box {
                    padding: 1.5em 2em 3em;
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body {
                display: flex;
                flex-wrap: wrap;
                padding: 0 5vw;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
                    flex-basis: 15%;
                    padding: 1em 0;
                }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(6n+6)) {
                        margin-right: 2%;
                    }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu a {
                        display: flex;
                        align-items: center;
                        color: #ffffff;
                    }

                        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu a:hover {
                            color: rgba(255, 255, 255, 0.5);
                            text-decoration: none;
                        }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-head {
                        font-weight: bold;
                    }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-item {
                        margin-top: 0.5em;
                    }

                        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-item:before {
                            content: '-';
                            display: block;
                            padding-right: 0.5em;
                        }

@media(max-width: 1199px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 18%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(6n+6)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(5n+5)) {
            margin-right: 2.5%;
        }
}

@media(max-width: 991px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 24.25%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(5n+5)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(4n+4)) {
            margin-right: 1%;
        }

    #SITE_CONTENT > .s-header .header-contact {
        font-size: 0.8em;
    }
}

@media(max-width: 800px) {
    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
        font-size: 1.15em;
    }
}

@media(max-width: 770px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 32%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(4n+4)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(3n+3)) {
            margin-right: 2%;
        }

    #SITE_CONTENT > .s-header .header-contact {
        font-size: 0.7em;
        /* display: none; */
    }

        #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon {
            margin: 0 2px;
        }

    /* #SITE_CONTENT > .s-header .header-contact  .conteact-panel {
            position: absolute;
            top: 100%;
            right: 55%;
            display: block;
            padding: 1em 0;
            background-color: rgba(0, 0, 0, 0.6);
            box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
            transition: padding .3s, height .3s;
            width: 50%;
            z-index: 10;
        }*/
}

@media(max-width: 575px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 47%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(3n+3)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(2n+2)) {
            margin-right: 6%;
        }

    /* #SITE_CONTENT > .s-header .header-contact {
            display: none;
        } */
    #SITE_CONTENT > .s-header .header-contact > .contact-us {
        display: block;
    }

    #SITE_CONTENT > .s-header .header-contact > .contact-item {
        display: none;
    }
}

@media(max-width: 415px) {
    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
        font-weight: 500;
        font-size: 1.1em;
    }

    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-2 {
        font-weight: 300;
        font-size: 1em;
    }
}

#SITE_CONTENT > .s-footer .footer-content {
    display: flex;
    background-color: #ffffff;
    height: 70px;
}

    #SITE_CONTENT > .s-footer .footer-content > .content-institution {
        padding: .5em 2em 1.5em;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution img {
            margin: 5px 15px;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-ptt {
            height: 2em;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-institution {
            height: 3em;
        }

    #SITE_CONTENT > .s-footer .footer-content > .content-private {
        padding: 1.45em 2em 1.5em;
        margin-left: auto;
        display: block;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-private > .private-title {
            font-size: 0.9em;
            color: #00a651;
            display: block;
        }

        /* #SITE_CONTENT>.s-footer .footer-content>.content-private >.private-title:after {
        content: "";
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: 0;
        right: -4px;
        box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 1);
        z-index: 10;
    } */

        #SITE_CONTENT > .s-footer .footer-content > .content-private > .private-desc {
            /* margin-left: 10px; */
            font-size: 0.9em;
            color: #00a651;
            display: block;
        }

    #SITE_CONTENT > .s-footer .footer-content > .content-contact {
        padding: 1.5em 2em 3em;
        margin-left: auto;
        text-align: right;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-contact > .contact-title {
            color: #299b89;
            font-size: 0.9em;
            font-weight: 500;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-contact > .contact-address {
            color: #495a72;
            font-size: 0.8em;
        }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel {
        display: none;
    }

@media (max-width: 991px) {
    #SITE_CONTENT > .s-footer .footer-content {
        flex-direction: column;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution {
            padding: 1em 2em;
            /* text-align: center; */
        }

    /* #SITE_CONTENT > .s-footer .footer-content > .content-contact {
            margin-left: 0;
            padding: 1em 2em;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-left-width: 0;
        } */
    /* #SITE_CONTENT>.s-footer .footer-content>.content-private {
            display: none;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .content-contact {
            display: none;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel {
            display: inline-flex;
            margin-left: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-left-width: 0;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private {
            padding: 2em 1.5em 2em 2em;
            font-size: 0.9em;
            width: 50%;
            display: block;
            align-items: center;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-title {
            position: relative;
            display: block;
            color: #00a651;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-title:after {
            content: "";
            position: absolute;
            top: -8px;
            bottom: -8px;
            left: 0;
            right: -4px;
            box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 1);
            z-index: 10;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-desc {
            color: #00a651;
            display: block;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact {
            width: 50%;
            padding: 2em 1.5em 2em 2em;
            text-align: right;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    
            #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-title {
                color: #299b89;
                font-size: 0.9em;
                font-weight: 500;
            }
            #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-address {
                color: #495a72;
                font-size: 0.8em;
            } */
}

@media (max-width: 575px) {
    #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-ptt {
        height: 1em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-institution {
        height: 2em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private {
        font-size: 0.75em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-title {
        color: #299b89;
        font-size: 0.8em;
        font-weight: 500;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-address {
        color: #495a72;
        font-size: 0.7em;
    }
}

#SITE_CONTENT > .s-block {
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    cursor: pointer;
    -moz-transition: -moz-transform .3s;
    -o-transition: -o-transform .3s;
    -webkit-transition: -webkit-transform .3s;
    transition: transform .3s, opacity .3s, visibility .4s;
}

    #SITE_CONTENT > .s-block .block-label {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 1.5em;
        color: #ffffff;
        opacity: 0.5;
    }

    #SITE_CONTENT > .s-block:hover .block-label {
        opacity: 1;
    }

#SITE_NAV {
    z-index: 30;
    position: fixed;
    top: 0;
    right: -270px;
    bottom: 0;
    outline: 0;
    width: 270px;
    max-width: 270px;
    background: #94b796;
    background: -moz-linear-gradient(top, #94b796 35%, #58827b 100%);
    background: -webkit-linear-gradient(top, #94b796 35%, #58827b 100%);
    background: linear-gradient(to bottom, #94b796 35%, #58827b 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#94b796', endColorstr='#58827b', GradientType=0);
    overflow: hidden;
    -webkit-transition: -webkit-transform ease 0.2s;
    transition: transform ease 0.3s;
}

    #SITE_NAV .menu {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 270px;
        list-style-type: none;
        padding: 0;
        margin: 0;
        -webkit-transition: -webkit-transform ease 0.2s;
        transition: transform ease 0.2s;
    }

        #SITE_NAV .menu li {
        }

        #SITE_NAV .menu a {
            position: relative;
            display: flex;
            padding: 12px 15px;
            font-size: 1em;
            color: #ffffff;
            transition: color .2s;
        }

            #SITE_NAV .menu a > .link-icon {
                margin-right: 5px;
                width: 1em;
            }

                #SITE_NAV .menu a > .link-icon > img {
                    max-width: 1em;
                    max-height: 1em;
                }

            #SITE_NAV .menu a > .link-label {
                flex-basis: 100%;
            }

            #SITE_NAV .menu a > .link-caret {
                padding-left: 5px;
            }

            #SITE_NAV .menu a:hover {
                background-color: rgba(37, 80, 88, 0.5);
                text-decoration: none;
            }

            #SITE_NAV .menu a.active {
                background-color: #255058;
            }

            #SITE_NAV .menu a.link-back {
                font-weight: 400;
            }

    #SITE_NAV ul.menu-sub {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        left: 100%;
        display: none;
        width: 100%;
    }

    #SITE_NAV a.menu-current + ul.menu-sub {
        display: block;
    }

@media (max-width: 1199px) {
    .nav-expanded {
        overflow: hidden;
    }

        .nav-expanded #SITE_NAV {
            -moz-transform: translate3d(-270px, 0, 0);
            -ms-transform: translate3d(-270px, 0, 0);
            -webkit-transform: translate3d(-270px, 0, 0);
            transform: translate3d(-270px, 0, 0);
        }

        .nav-expanded #SITE_CONTENT > .s-block {
            visibility: visible;
            opacity: 0.75;
            -moz-transform: translate3d(-270px, 0, 0);
            -ms-transform: translate3d(-270px, 0, 0);
            -webkit-transform: translate3d(-270px, 0, 0);
            transform: translate3d(-270px, 0, 0);
            transition: transform .3s, opacity .3s;
        }
}



#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li > .menu-sub {
    display: none;
    position: absolute;
    /*    top: 150%;*/
    left: 50%;
    transform: translateX(-50%);
    background: #e3fbf5 !important;
    list-style-type: none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 0;
    width: 350px;
    height: auto;
    border-radius: 0 !important;
    font-size: 1em !important;
}


#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li:hover > .menu-sub {
    display: block;
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li:hover > .menu-sub:before {
        content: '';
        display: block;
        top: -15px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        /*        border-top: 15px solid #779D89;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;*/
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub li {
    /*Gky border-bottom: 1px solid rgba(0, 0, 0, 0.15);*/
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub li + li {
        /*Gky border-top: 1px solid rgba(0, 0, 0, 0.1);*/
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a {
    position: relative;
    /*Gky  font-weight: normal;*/
    /*padding: 20px 30px;*/
    text-transform: capitalize;
    white-space: unset;
    color: #255058;
    font-weight: bold; /*Gky*/
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a.active {
        background-color: #219583;
        color: #ffffff;
    }


#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu-sub li:hover {
    display: block;
    /*   background-color: red !important;*/
}

/*#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li:hover:before {
    content: none;
}*/

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a {
    padding: .5rem;
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a:hover {
        background-color: #d0ebd3;
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub {
    /*top: 0;
    left: 100%;*/
    position: relative;
    border-radius: 0 !important;
    display: block;
    height: 100%;
    padding-left: 1rem;
    margin-bottom: .5rem;
}



    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub a {
        font-weight: normal;
    }

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub li {
        border-bottom: 1px solid lightgrey;
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .menu:not(.menu-sub) > li > .menu-sub {
    padding: 1rem !important;
}

.menu-sub .menu-sub {
    list-style: none; /* Remove default bullets */
}

    .menu-sub .menu-sub li::before {
        content: ""; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: green; /* Change the color */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: .25rem;
        height: .25rem;
        border-radius: 100%;
        background-color: green;
    }

#PAGE_ADMIN #SITE_CONTENT {
  position: relative;
  min-height: 100vh;
  padding-bottom: 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header {
  width: 100%;
  position: fixed;
  /*top: 25px;*/
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 999;
  /* padding: 10px 20px; */
  padding: 0px 20px;
  justify-content: space-between;
  box-shadow: 0px 5px 5px -7px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../../Images/91250.jpg);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(left,  rgba(255,255,255,1) 25%, rgba(125,185,232,0) 100%);
  background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 25%,rgba(125,185,232,0) 100%);
  background: linear-gradient(to right,  rgba(255,255,255,1) 25%,rgba(125,185,232,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 );


}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #ffffff;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 15px;
  margin-right: 15px;
  z-index: 10;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.5);
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title {
  float: left;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web {
  position: relative;
  bottom: 4px;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
  width: 80px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
  line-height: 1;
  margin-left: 15px;
  color: #000000;
  display: flex;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
  font-size: 1.3em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
    font-size: 1.75em;
    margin-left: 7px;
    color: #015d26;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-shadow: 1px 1px 2px #015d26;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
  padding-right: 12px;
  line-height: 1.1;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
  /* color: #000000; */
  color: #ffffff;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
  font-size: 0.8em;
  color: #666666;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
  white-space: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body {
  position: relative;
  display: flex;
  /*padding-top: 60px;*/
  /* background-color: rgba(223, 223, 223, 0.2); */
  min-height: calc(100vh - 30px);
  /* min-height: 100vh; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side {
  position: relative;
  display: block;
  flex: 0 0 240px;
  max-width: 240px;
  transition: all .3s;
  background-image: url(../../Images/aerial-view-green-forest.jpg);
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 99;
  /* background-color: #edf8ff; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0, 0.2);
}
/* #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.5);
} */
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav {
  z-index: 1;
  position: fixed;
  top: 50px;
  left: 0;
  /* width: 235px; */
  width: 240px;
  max-height: calc(100vh - 155px);
  padding: 5px 0;
  transition: width .3s;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
  position: relative;
  height: calc(100vh - 90px);
  /* padding-right: 7px; */
  overflow: hidden;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu {
  position: relative;
  display: block;
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 0;
  padding-left: 0;
  /* padding-left: 15px; */
  cursor: pointer;
  transition: transform .3s;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu li+li {
  margin-top: 10px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a {
  position: relative;
  display: flex;
  /* padding: 10px; */
  font-size: 1em;
  line-height: 1;
  /* color: #424949; */
  color: #ffffff;
  align-items: center;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-icon {
  /* display: block;
  flex-basis: 1.8em;
  min-width: 1.8em;
  width: 1.8em;
  height: 1.8em;
  font-size: 1.2em;
  line-height: 1.8em;
  text-align: center;
  color: #ffffff; */
  display: block;
  min-width: 50px;
  padding: 13px 0;
  font-size: 1.357142857142857em;
  line-height: 1;
  text-align: center;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-icon>i {
  line-height: inherit;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label {
  display: block;
  flex-basis: 100%;
  margin-left: 15px;
  padding: 0.25em 0;
  transition: all .3s;
  line-height: 1.3;
  /* font-size: 0.9em; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label .labe-leng {
  font-size: 1em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label .label-thai {
  font-size: 0.8em;
}
/* #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.line:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  box-shadow: inset -1px 0px 0px 0px rgb(0 0 0 / 50%);
} */
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-caret {
  font-size: 0.85em;
  line-height: 2em;
  padding-right: 15px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.6);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.active {
  /* color: #000000;
  background-color: #ffffff;
  box-shadow: inset 5px 0px 0px 0px rgb(111, 215, 210, 1); */
  /* padding: 15px 10px; */
  background-color: rgba(0, 0, 0, 0.6);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.active>.link-icon {
  /* color: #6fd7d2; */
  width: 50px;
  background-color: #8ec549;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu .menu-sub {
  position: absolute;
  top: 0;
  list-style-type: none;
  display: none;
  margin: 0;
  left: 100%;
  width: 100%;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.menu-current+.menu-sub {
  display: block;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main {
  display: block;
  flex-grow: 1;
  /* padding: 20px 15px; */
  padding: 20px 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head {
  justify-content: space-between;
  /* display: flex; */
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}
/*#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag {
  padding-top: 15px;
}*/
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag>.flag-item {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-icon {
  display: block;
  color: #141e46;
  font-size: 1.2em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-title {
  display: block;
  font-size: 1.2em;
  color: #273746;
  padding-left: 10px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-body {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 30px;
  background-color: #417c4e;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel {
  display: flex;
  width: 100%;
  height: 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content {
  padding: 8px 20px 7px 25px;
  line-height: 1;
  text-align: end;
  width: 100%;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content>.footer-title {
  color: #fcfcff;
  font-size: 0.8em;
}
#PAGE_ADMIN #MENU_SWITCHER {
  z-index: 99;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 30px;
  line-height: 30px;
  font-size: 1.1em;
  text-align: center;
  border-width: 0;
  border-radius: 50em;
  background-color: transparent;
  outline: none;
}
#PAGE_ADMIN #MENU_SWITCHER:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse {
  flex: 0 0 50px;
  max-width: 50px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav {
  /* width: 65px; */
  width: 50px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .nav-scroller {
  overflow: visible;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu {
  /* padding-left: 7px; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu li {
  height: 45px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a>.link-label {
  display: none;
  white-space: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a>.link-caret {
  font-size: 0.6em;
  margin-left: 1px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a.menu-current {
  display: none;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover {
  position: absolute;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover>.link-label {
  display: block;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover>.link-caret {
  font-size: 0.85em;
  line-height: 2em;
  margin-left: 10px;
}

@media (max-width: 991px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-body {
      display: block;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side {
    background-image: url(../../Images/aerial-view-green-forest.jpg);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.2);
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav {
      /* top: 80px; */
      padding: 0;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu {
      /* padding: 10px 15px; */
      /* background-color: rgba(0, 0, 0, 0.85); */
      background-image: url(../../Images/aerial-view-green-forest.jpg);
      background-position-x: center !important;
      background-position-y: top !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-attachment: fixed;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      min-height: calc(100vh - 30px);
      margin-top: 5px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.2);
}
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
      height: auto;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse {
      display: none;
  }
}

@media (max-width: 815px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
      height: calc(100vh - 90px);
  }
}

@media (max-width:768px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 30px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
    font-size: 0.95em;
    margin-left: 0;
}
}

@media (max-width:575px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
      height: 55px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
      width: 60px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 0px;
      padding-left: 0px;
      display: block;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.95em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.8em;
      margin-left: 0;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 7px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
      padding-right: 15px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.9em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.8em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
      color: #666666;
      font-size: 1em;
  }
}

@media (max-width:415px) {
  /* #PAGE_ADMIN #SITE_CONTENT {
      position: absolute;
  } */
  #PAGE_ADMIN #SITE_CONTENT>.content-header {
      padding: 0px 2px;
      position: absolute;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
      height: 55px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
      width: 40px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 0px;
      padding-left: 4px;
      margin-left: 7px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.8em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.75em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
      padding-right: 4px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.65em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
      color: #666666;
      font-size: 0.85em;
      /* display: block; */
      display: flex;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool > .btn-logout {
      margin-top: 5px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
      padding-right: 3px;
      margin-right: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main {
      padding: 20px 15px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag {
      font-size: 0.9em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content {
    padding: 10px 20px 7px 25px;
}
  #PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content>.footer-title {
    color: #fcfcff;
    font-size: 0.6em;
  }

}
@media (max-width:376px) {
  #PAGE_ADMIN #SITE_CONTENT {
    position: relative;
}
  #PAGE_ADMIN #SITE_CONTENT>.content-header {
    padding: 0px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 2px;
      padding-left: 0px;
      margin-left: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.7em;
      color: #000000;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.65em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
    padding-right: 0px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
    display: grid;
  }
}

#PAGE_ADMIN .fileuploader {
  padding: 0;
  margin: 0;
}
#PAGE_ADMIN .navTabList .nav-link {
  color: #666666;
}
#PAGE_ADMIN .navTabList .nav-link:hover {
  cursor: pointer;
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
#PAGE_ADMIN .navTabList .nav-link.active {
  box-shadow: inset 0px 2px 0px 0px #0d6efd;
  color: #141e46;
}
#PAGE_ADMIN .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  padding: 10px 15px;
}
#PAGE_HOME {
    position: relative;
    display: block;
    background-color: #ffffff;
    background-image: url(../../Images/home/bg-main.png);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: calc(100vh - 80px);
}

#PAGE_HOME::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

#PAGE_HOME>.home-banner {
    z-index: 1;
    position: relative;
    height: calc(100vh - 80px);
    max-height: calc(100vw * 9 / 16);
}

#PAGE_HOME>.home-banner>.logo-reforest {
    z-index: 1;
    position: absolute;
    top: 10%;
    left: 10%;
    display: block;
    width: 12.5vw;
    height: 12.5vw;
    background-image: url(../../Images/home/logo-reforest.png);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_HOME>.home-banner>.slick-slider {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.25);
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track {
    height: 100%;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide {
    position: relative;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* #PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #229684; */
    /* background: -moz-linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#224c15', endColorstr='#00ffffff', GradientType=1);
} */

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-content {
    z-index: 1;
    position: absolute;
    top: calc(10% + 14vw);
    bottom: calc(10% + 8vw);
    left: 10%;
    /* right: 50%; */
    display: block;
    font-size: 2vw;
    line-height: 1;
    color: #ffffff;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-link {
    z-index: 2;
    position: absolute;
    left: 5%;
    bottom: calc(5% + 0vw);
    /* bottom: calc(10% + 4vw); */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0.8em;
    font-size: 1vw;
    color: #ffffff;
    background-color: #3da393;
    border-radius: 35px;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-link:hover {
    background-color: #346460;
    text-decoration: none;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow {
    z-index: 2;
    top: auto;
    left: 93%;
    bottom: 5%;
    transform: none;
    width: auto;
    height: auto;
    font-weight: bold;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow::before {
    font-size: 2vw;
    font-family: IcoFont;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 50%;
    opacity: 1;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow:hover::before {
    opacity: 0.75;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-prev {}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-prev::before {
    content: "\eac9";
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-next {
    margin-left: 3vw;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-next::before {
    content: "\eaca";
}

#PAGE_HOME>.home-news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 3vw 5vw 1vw 5vw;
}

#PAGE_HOME>.home-news>.news-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #519f39;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.home-news>.news-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.news-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.home-news>.news-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.home-news>.news-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.home-news>.news-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.home-news>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    background: #229684;
    background: -moz-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: -webkit-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
    color: #ffffff;
    font-size: 1.2em;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title-first {
    display: flex;
    padding: 0.25em 0.75em 0.25em 0em;
    color: #ffffff;
    /* border-left: 1px solid #ffffff; */
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link>.link-label {}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link>.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link:hover>.link-label {
    text-decoration: underline;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link:hover>.link-icon {
    color: #229684;
    background-color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel {
    display: flex;
    flex-direction: column;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    background: #229684;
    background: -moz-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: -webkit-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-title-first {
    display: flex;
    padding: 0.25em 0.75em 0.25em 0em;
    color: #ffffff;
    /* border-left: 1px solid #ffffff; */
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link>.link-label {}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link>.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link:hover>.link-label {
    text-decoration: underline;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link:hover>.link-icon {
    color: #229684;
    background-color: #ffffff;
}

    #PAGE_HOME > .home-news > .map-panel > .panel-body-content {
        margin-top: 1.5vw;
        background-color: #ffffff;
    }

#PAGE_HOME>.news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 1.5vw 5vw 1vw 5vw;
}

#PAGE_HOME>.news>.news-content {
    padding: 0 6vw;
}

#PAGE_HOME>.news>.news-content>.content {
    /* background-color: rgba(37, 80, 88, 0.2); */
    background-color: #ffffff;
    padding: 1em 2.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-detail {
    font-size: 0.95em;
    margin: 1em 0;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-img {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0.5em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
    height: calc((50vh - 70px) * 0.5);
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

#PAGE_HOME>.news>.news-content>.content>.news-item>.item-box>.item-cover {
    flex-basis: 100%;
    background-color: #eeeeee;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1em;
}

#PAGE_HOME>.news>.news-content>.content>.news-ref {
    margin-bottom: 1.2em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-ref>.ref-title {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-file {
    margin-bottom: 0.5em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-file>.file-title {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.3em 1em;
    color: #ffffff;
    background: #e4ab4a;
    justify-content: center;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    text-align: center;
}

#PAGE_HOME>.row-date {
    z-index: 1;
    position: relative;
    padding: 0 5vw 1vw 5vw;
}

#PAGE_HOME>.row-date>.date-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.row-date>.date-panel>.news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.news>.news-content>.content>.news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.search {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 0vw 5vw 1vw 5vw;
}

#PAGE_HOME>.search>.search-content {
    padding: 0 6vw;
}

#PAGE_HOME>.search>.search-content>.content {
    /* background-color: rgba(37, 80, 88, 0.2); */
    background-color: #ffffff;
    padding: 0em 1.2em 1em 1.2em;;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body-content > .news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

#PAGE_HOME > .home-news > .news-panel > .panel-body-content {
    /*display: flex;*/
    margin-top: 1.5vw;
    /*height: 16vw;*/
    /*background-color: rgba(37, 80, 88, 0.2);*/
    /*-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}


#PAGE_HOME > .home-news > .news-panel > .panel-body-content > .news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    /*background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item {
    position: relative;
    display: block;
    flex-basis: 24%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body>.news-item>.news-date, #PAGE_HOME>.home-news>.news-panel>.panel-body-transparent>.news-item>.news-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:hover::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:hover::after {
    opacity: 0.25;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary {
    flex-basis: 31%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-date, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary:hover::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:last-of-type {
    margin-right: 1%;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        height: 18.5vw;
    }
    #PAGE_HOME>.home-news>.map-panel {
        padding: 0;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        height: 20vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-news {
        /* padding: 3vw; */
        padding: 7vw 3vw 3vw 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
        margin: 1.5em 0 0 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME>.home-news>.map-panel>.panel-head {
        font-size: 0.8em;
        margin: 1.5em 0 0 0;
    }
}

@media(max-width: 575px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        /* display: block; */
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title {
        padding: 1em 0.75em 1em 0.75em;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-head .search {
        margin-top: 10px;
    }

    #SITE_CONTENT > .s-header > .header-search {
        width: 100%;
    }
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box {
    display: flex;
    margin: 1.2vw 0;
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag {
    position: relative;
    display: block;
    padding: 1.5em 0.6em;
    font-size: 1em;
    line-height: 1;
    text-align: center;
    width: 147px;
    /* flex-basis: 20%; */
    height: 4.5vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 60%);
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #000000;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title {
    position: relative;
    color: #000000;
    font-weight: bold;
    background-color: #F0FFF0;
    padding: 5px 0px;
}

/* #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag + .box-flag {
    margin-left: 15px;
} */

#PAGE_HOME>.home-history {
    z-index: 1;
    position: relative;
}

#PAGE_HOME>.home-history>img {
    width: 100%;
}

#PAGE_HOME>.home-calendar {
    z-index: 1;
    position: relative;
    padding: 3vw 5vw;
}

#PAGE_HOME>.home-calendar>.calendar-box {
    display: flex;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #239684;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    color: #000000;
    padding: 0.5em 0;
    margin-left: auto;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
    list-style-type: none;
    background-color: #ffffff;
    -webkit-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.2);
    /* padding: 0.8em 0; */
    height: 60px;
    width: 20%;
    margin-left: auto;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button> li+li {
    margin-left: 1em;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button a {
    display: flex;
    align-items: center;
    height: 100%;
    /* padding: 0.25em 1.5em; */
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-img {
    position: relative;
    align-items: center;
    display: block;
    /* flex-basis: 1.8em; */
    /* min-width: 25%; */
    font-size: 1.2em;
    line-height: 1.8em;
    text-align: center;
    color: #ffffff;
    width: 3.5em;
}

/* #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-title:after {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.75);
} */

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-img>img {
    /* width: 35px;
    height: 35px; */
    width: 105%;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-title {
    display: flex;
    vertical-align: middle;
    flex-basis: 100%;
    align-items: center;
    /* padding-top: 1em; */
    padding-left: 0.5em;
    transition: all .3s;
    line-height: 1.3;
    height: 100%;
    border-left: 1px solid rgba(220, 215, 215, 0.75);
    font-size: 14px;
    /* overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    max-width: 230px; */
    /*width: 220px; */
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li.active .button-title {
    background-color: #84a94a;
    color: #ffffff;
}

#PAGE_HOME>.home-calendar>.calendar-panel {
    display: block;
    margin-top: 1.5vw;
    background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity {
    position: relative;
    display: block;
    cursor: pointer;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
    top: calc(22.5vw * 3 / 4 * 0.5);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev:before,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next:before {
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item {
    position: relative;
    display: block;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
    position: relative;
    display: block;
    height: calc(22.5vw * 3 / 4);
    background-color: #bbbbbb;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover>.activity-date {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 5em;
    height: 5em;
    color: #ffffff;
    background-color: rgba(35, 150, 132, 0.5);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover>.activity-date::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 2em solid rgba(35, 150, 132, 0.5);
    border-right: 5em solid transparent;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
    padding: 1em 0.75em;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.3;
    max-height: 2.5em;
    overflow: hidden;
    padding-right: 0.75em;
    font-weight: 500;
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-caret {
    position: relative;
    display: block;
    width: 2.5em;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-caret::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item:hover>.activity-cover::after {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(35, 150, 132, 0.15);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item:hover>.activity-title>.title-caret::before {
    color: #ffffff;
    background-color: #37a08f;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date {
    display: block;
    padding: 1.5vw 1vw;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-prev {
    left: 0;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-next {
    right: 0;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-prev:before,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-next:before {
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    margin: 0 auto;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    color: #239684;
    font-weight: 500;
    border-radius: 50%;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link:hover {
    background-color: rgba(35, 150, 132, 0.15);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link.active {
    color: #ffffff;
    background-color: #239684;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-slider {
        padding: 0 2.5em;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-calendar>.calendar-title {
        font-size: 0.9em;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title {
        font-size: 0.7em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-calendar>.calendar-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
        top: calc(30vw * 3 / 4 * 0.5);
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
        height: calc(30vw * 3 / 4);
    }
}

@media(max-width: 575px) {
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
        top: calc(45vw * 3 / 4 * 0.5);
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
        height: calc(45vw * 3 / 4);
    }
}

#PAGE_HOME>.home-booking {
    z-index: 1;
    position: relative;
    padding: 3vw 5vw;
}

#PAGE_HOME>.home-booking>.booking-head {
    display: flex;
    justify-content: center;
}

#PAGE_HOME>.home-booking .booking-title {
    display: block;
    margin: 0 auto;
    padding: 0.25em;
    min-width: 60%;
    text-align: center;
    font-size: 2.5vw;
    color: #239684;
    border-top: 1px solid #239684;
    border-bottom: 1px solid #239684;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots {
    bottom: 0;
    right: 0;
    display: flex !important;
    width: auto;
    padding: 1.5vw;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li>button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li>button::before {
    content: none;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li:hover>button {
    opacity: 0.5;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li.slick-active>button {
    opacity: 1;
}

#PAGE_HOME>.home-booking .booking-link {
    position: relative;
    display: block;
    height: 18vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.5vw;
    overflow: hidden;
}

#PAGE_HOME>.home-booking .booking-link::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border-radius: 1.5vw;
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME>.home-booking .booking-link:hover::after {
    opacity: 0.3;
}

#PAGE_HOME>.head-news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 0vw 5vw 1vw 5vw;
}

#PAGE_HOME>.head-news>.news-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #519f39;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.head-news>.news-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.head-news>.news-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.head-news>.news-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.head-news>.news-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.head-news>.news-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.head-news>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    color: #ffffff;
    background: #229684;
    background: -moz-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: -webkit-linear-gradient(left, #229684 0%, #51b6a6 100%);
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.head-news>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
}

.ant-tooltip {
    max-width: 400px;
}

@media (max-width:1099px) {
    #PAGE_HOME>.home-calendar>.calendar-box {
        display: block;
    }
}

@media (max-width:768px) {
    #PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button {
        flex-wrap: wrap;
        margin-left: 0;
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
        flex-basis: 50%;
        width: 50%;
        margin-left: 0;
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button> li+li {
        margin-left: 0;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box {
        display: flex;
        width: 100%;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 0.5em 0.6em;
        font-size: 0.7em;
    }
}

@media (max-width:575px) {
    #PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
        flex-basis: calc(100% / 1);
        max-width: calc(100% / 1);
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
        flex-basis: 100%;
        width: 100%;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box {
        display: block;
        width: 100%;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 1.5em 0.6em;
        font-size: 0.9em;
        height: 13vw;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag+.box-flag {
        margin-left: 0px;
    }
}

@media (max-width:415px) {

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 1.1em 0.6em;
        font-size: 0.9em;
        height: 15vw;
    }
}






#PAGE_HOME .contents-index {
    z-index: 1;
    position: relative;
    /* padding: 1.5vw 0 1vw 0; */
    padding: 0 0 1vw 0;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);
}

/* #PAGE_HOME .contents-index>.index-contents {
    padding: 0 6vw;
} */

/* #PAGE_HOME .contents-index>.index-contents>.index {
    background-color: #ffffff;
    padding: 1em 1.2em;
} */



#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    /*background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item {
    position: relative;
    display: block;
    flex-basis: 24%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .news-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        /* background-color: #bbbbbb; */
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
    z-index: 2;
    position: absolute;
    bottom: 1.2em;
    left: 0.5em;
    right: 0.5em;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.3;
    height: 3.5em;
    color: #000000;
    justify-content: center;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    /* background-color: #b5e8c2; */
    background-color: #94b796;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    transition: all .3s;
    padding: 0 0.5em;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover > .contents-cover {
    background-color: #dddddd;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover > .contents-title {
    background-color: #dddddd;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body>.contents-item>.contents-date, #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-transparent>.contents-item>.contents-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:hover::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:hover::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover::after {
    opacity: 0.2;
} */

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.news-primary, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary {
    flex-basis: 31%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-date, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary:hover::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary:hover::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:nth-of-type(3), #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:nth-of-type(3) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:last-of-type, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:last-of-type {
    /* margin-right: 1%; */
    margin-left: 1%;
}

@media(max-width: 1199px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        height: 18.5vw;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        height: 20vw;
    }
    /* #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        right: 2.5%;
    } */
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 768px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }

    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        left: 0em;
        right: 0em;
    }
}


#LOGIN {
    display: flex;
    /* flex-direction: row-reverse; */
    width: 100%;
    min-height: 100vh;
    align-items: center;
    color: #000000;
    background-image: url(assets/bg-login.jpg);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

    #LOGIN::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #ffffff;
    }

    #LOGIN .box-overview {
        flex-basis: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column-reverse;
    }

        #LOGIN .box-overview > .box-cover {
            flex-basis: 100%;
            /*        background-image: url(assets/smart-agriculture-iot-with-hand-planting-tree-background.jpg);*/
            background-position-x: center;
            background-position-y: center;
            background-repeat: repeat;
            background-size: cover;
        }

    #LOGIN .box-login {
        flex-basis: calc(350px + 10vw);
        min-width: calc(350px + 10vw);
        max-width: 100%;
        position: absolute;
        left: 5%;
        padding: 0 5vw;
        transition: all .3s;
    }

        #LOGIN .box-login .box-title {
            line-height: 1;
         /*   text-transform: uppercase;*/
            /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); */
            margin: 10px 0;
        }

            #LOGIN .box-login .box-title.title-1 {
                font-size: 1.5em;
                font-weight: 500;
                color: white;
            }

            #LOGIN .box-login .box-title.title-2 {
                font-size: 1.8em;
                font-weight: 600;
            }

        #LOGIN .box-login .login-input {
            position: relative;
        }

            #LOGIN .box-login .login-input > i {
                position: absolute;
                padding: 15px;
                color: rgb(30, 25, 108, 0.89);
                width: 70px;
                text-align: left;
                font-size: 1.2em;
            }

            #LOGIN .box-login .login-input > input[type="text"],
            #LOGIN .box-login .login-input > input[type="password"] {
                display: block;
                width: 100%;
                padding: 10px 15px;
                padding-left: 3.3em;
                color: #333333;
                background-color: rgba(255, 255, 255, 0.9);
                border: 2px solid #ffffff;
                /* border-radius: 50em; */
                outline: none;
                font-size: 1em;
                line-height: 1.5;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                /* -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
                transition: background-color .3s;
            }

                #LOGIN .box-login .login-input > input[type="text"]:focus,
                #LOGIN .box-login .login-input > input[type="password"]:focus {
                    background-color: #ffffff;
                }

            #LOGIN .box-login .login-input > input::-webkit-input-placeholder,
            #LOGIN .box-login .login-input > input:-ms-input-placeholder,
            #LOGIN .box-login .login-input > input::placeholder {
                color: #999999;
            }

        #LOGIN .box-login button {
            /* padding: 10px 20px; */
            padding: 5px 20px;
            border-radius: 50em;
            outline: none;
            font-size: 1em;
            line-height: 1.5;
            border-width: 2px;
            border-style: solid;
        }

@media (max-width: 1199px) {
    #LOGIN .box-login {
        flex-basis: calc(300px + 10vw);
        min-width: calc(300px + 10vw);
    }

    #LOGIN .box-overview > .item-list {
        flex-wrap: wrap;
    }

        #LOGIN .box-overview > .item-list > .item-frame {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
            padding: 10px;
        }

            #LOGIN .box-overview > .item-list > .item-frame:nth-of-type(4n) {
                border-left: none;
            }

    #LOGIN .box-login .box-title.title-1 {
        font-size: 1.4em;
    }

    #LOGIN .box-login .box-title.title-2 {
        font-size: 2.2em;
    }
}

@media (max-width: 991px) {
    #LOGIN {
        justify-content: center;
    }

        #LOGIN .box-login {
            flex-basis: auto;
            padding: 0 15px;
            width: 400px;
            min-width: auto;
            max-width: 100%;
        }
}

@media (max-aspect-ratio: 1/1), (max-width: 991px) {
    #LOGIN .box-overview {
        display: none;
    }
}

@media (max-width: 575px) {
    #LOGIN {
        justify-content: center;
    }

        #LOGIN .box-login {
            flex-basis: auto;
            padding: 0 40px;
            width: 400px;
            min-width: auto;
            max-width: 100%;
        }
}

@import url(//fonts.googleapis.com/css?family=Roboto:400,300,300italic,400italic,700,700italic&subset=latin,vietnamese,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek);
@import url(//fonts.googleapis.com/css?family=Oswald:400,300,700&subset=latin,latin-ext);
@import url(//fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700&subset=latin,greek,greek-ext,vietnamese,cyrillic-ext,cyrillic,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Charmonman&family=Chonburi&family=Mitr:wght@200&family=Pattaya&family=Prompt:wght@100;300&family=Sriracha&family=Srisakdi&display=swap);
@font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-Thin.ddcfbf89.ttf) format("truetype");
    font-weight: 100;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-ThinItalic.9fc776b0.ttf) format("truetype");
    font-weight: 100;
    font-style: italic; }
  
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-ExtraLight.cec66179.ttf) format("truetype");
    font-weight: 200;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-ExtraLightItalic.7780f529.ttf) format("truetype");
    font-weight: 200;
    font-style: italic; }
  
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-Light.2aed5273.ttf) format("truetype");
    font-weight: 400;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-LightItalic.58b20c7b.ttf) format("truetype");
    font-weight: 400;
    font-style: italic; }
  
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-Regular.16b4ce72.ttf) format("truetype");
    font-weight: 500;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-Italic.80d507e9.ttf) format("truetype");
    font-weight: 500;
    font-style: italic; }
  
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-Medium.bd536c63.ttf) format("truetype");
    font-weight: 700;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-MediumItalic.a1de5368.ttf) format("truetype");
    font-weight: 700;
    font-style: italic; }
  
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-SemiBold.ba9b20ec.ttf) format("truetype");
    font-weight: 800;
    font-style: normal; }
  @font-face {
    font-family: "Prompt";
    src: url(/static/media/Prompt-SemiBoldItalic.ba8ac934.ttf) format("truetype");
    font-weight: 800;
    font-style: italic; }
  
  .Prompt {
    font-family: "Prompt";}
/* Provide sufficient contrast against white background */
body {
    margin: 0 auto;
    font-family: Prompt, Tahoma !important;
    font-size: 16px;
    line-height: 1.1;
}

a {
    color: #0366d6;
    text-decoration: none !important;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.ant-collapse-header {
    background-color: #027DB4;
    color: #ffffff !important;
}

.ant-input[disabled] {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.ant-picker-input>input[disabled] {
    color: rgba(0, 0, 0, 0.85);
}

.ant-picker.ant-picker-disabled {
    cursor: not-allowed;
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
}

textarea.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: #f5f5f5;
}

.ant-radio-disabled+span {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
}

.ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
}

.form-search {
    position: relative;
    width: 100% !important;
}

.form-search::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    padding: 0.375rem 0.75rem;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.form-search .form-control {
    padding-right: 3.25em;
}

tr.ant-table-row.ant-table-row-level-0 .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    display: none;
}

.react-calendar {
    width: 100% !important;
    min-width: 310px !important;
    max-width: 100%;
    font-family: inherit !important;
    border: 1px solid #ced4da !important;
}

.ant-input-affix-wrapper-disabled {
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
}

.react-calendar__navigation {
    height: 40px !important;
}

.react-calendar__navigation button {
    min-width: 40px !important;
}

.react-calendar__navigation__label {
    font-weight: bolder;
}

.react-calendar__month-view__weekdays {
    font-size: 1em !important;
}

.react-calendar abbr[title],
.react-calendar abbr[data-original-title] {
    text-decoration: none;
}

.fileuploader,
.fileuploader-popup {
    font-family: inherit;
}

.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-meta h5 {
    color: #ffffff;
}

.fileuploader-items .fileuploader-item .columns {
    align-items: center;
}

.fileuploader-disabled .fileuploader-input {
    opacity: .7;
    cursor: default;
    pointer-events: none !important;
    display: none;
}

div.fileuploader-input-caption form-control {
    color: red;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child).input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    color: #343a40;
    border-radius: 2px;
    margin-right: 1px;
}

.block-ui-overlay {
    opacity: 0.7 !important;
    background-color: #000000 !important;
}

.loading-bullet {
    color: #ffffff !important;
}

@media (min-width: 1300px) {
    .container {
        max-width: 1280px;
    }
}

.block-ui-container {
    position: fixed !important;
}

.ant-picker-content {
    height: 112px !important;
}

.ant-picker-time-panel-column::after {
    height: 0px;
}

.ant-table-small .ant-table-thead>tr>th {
    background-color: #e6ffe5;
}

.ant-table-small .ant-table-thead>tr>th:hover,
.ant-table-small .ant-table-thead>tr>th:focus {
    background-color: #e6ffe5;
}

.ant-table-column-sorters .ant-table-column-sorter {
    color: #000000;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #999999;
}

.ant-table-thead>tr>th {
    text-align: center !important;
}

.ant-switch {
    background-color: #cc2838;
}

.ant-switch-checked {
    background-color: #60cc28;
}

.ant-input {
    border-radius: 1em;
}

.ant-input-affix-wrapper {
    border-radius: 1em;
}

.ant-input-affix-wrapper>.ant-input {
    border-radius: 0;
}

.ant-select-selector {
    border-radius: 1em !important;
}

.ant-pagination-item-link,
.ant-pagination-item {
    border-radius: 1em !important;
}

.ant-pagination-item.active {
    font-weight: 500;
    background: #fff;
    border-color: #255058;
}

.ant-pagination-item:hover {
    color: #255058;
    border-color: #255058;
}

.ant-pagination-item > .ant-pagination-item-link:hover {
    color: #255058;
}

div#canvasLoader {
    margin-left: -3.5%;
    margin-top: 3.5%;
}
#SITE_CONTENT {
    z-index: 0;
    position: relative;
    display: block;
    min-height: 100vh;
    padding-top: 60px;
}

    #SITE_CONTENT > .s-header {
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        padding: 0 15px;
        height: 80px;
        color: #ffffff;
        background-color: #255058;
    }

        #SITE_CONTENT > .s-header > .header-title {
            display: flex;
            align-items: center;
            margin-right: auto;
        }

            #SITE_CONTENT > .s-header > .header-title > .title-icon {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 10px;
                border-right: 1px solid #ffffff;
            }

                #SITE_CONTENT > .s-header > .header-title > .title-icon > img {
                    height: 40px;
                }

            #SITE_CONTENT > .s-header > .header-title > .title-label {
                padding-left: 10px;
                color: #ffffff;
            }

                #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
                    font-weight: 500;
                    font-size: 1.45em;
                }

                #SITE_CONTENT > .s-header > .header-title > .title-label > .label-2 {
                    font-weight: 300;
                    font-size: 1.1em;
                }

        #SITE_CONTENT > .s-header > .header-content {
            display: flex;
            flex-direction: column;
        }

        #SITE_CONTENT > .s-header > .header-search {
            position: absolute;
            top: 100%;
            /* left: 0; */
            right: 0;
            display: block;
            padding: 1em 0;
            background-color: rgba(150, 180, 150, 0.75);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            transition: padding .3s, height .3s;
            width: 45%;
        }

            #SITE_CONTENT > .s-header > .header-search.collapsed {
                padding: 0;
                height: 0;
                overflow: hidden;
            }

            #SITE_CONTENT > .s-header > .header-search .ant-input {
                background-color: rgba(255, 255, 255, 0.75);
            }

        #SITE_CONTENT > .s-header .header-contact {
            display: flex;
            justify-content: end;
            align-items: center;
            height: 35px;
        }

            #SITE_CONTENT > .s-header .header-contact > .contact-us {
                display: none;
            }

            #SITE_CONTENT > .s-header .header-contact > .contact-item {
                padding: 0.15em 0.5em;
            }

                #SITE_CONTENT > .s-header .header-contact > .contact-item + .contact-item {
                    border-left: 1px solid #ffffff;
                    margin-left: 15px;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon {
                    display: inline-block;
                    flex-basis: 1.85em;
                    min-width: 1.85em;
                    width: 1.85em;
                    height: 1.85em;
                    line-height: 1.85em;
                    font-size: 0.8em;
                    text-align: center;
                    color: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    margin: 0 4px;
                }

                    #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon > i {
                        line-height: inherit;
                    }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .facebook.contact-item-icon:hover {
                    background: #1877f2;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .line.contact-item-icon:hover {
                    background: #00d145;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .ins.contact-item-icon:hover {
                    background: linear-gradient(to right top, #fccc63, #fcb758, #fba250, #f88d4d, #f3774d, #f06752, #eb5757, #e5475e, #df3b6a, #d63175, #cb2b81, #bc2a8d);
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .youtube.contact-item-icon:hover {
                    background: #ff0000;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .facebook.contact-item-icon.active {
                    background: #1877f2;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .line.contact-item-icon.active {
                    background: #00d145;
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .ins.contact-item-icon.active {
                    background: linear-gradient(to right top, #fccc63, #fcb758, #fba250, #f88d4d, #f3774d, #f06752, #eb5757, #e5475e, #df3b6a, #d63175, #cb2b81, #bc2a8d);
                    border: none;
                }

                #SITE_CONTENT > .s-header .header-contact > .contact-item .youtube.contact-item-icon.active {
                    background: #ff0000;
                    border: none;
                }

            #SITE_CONTENT > .s-header .header-contact .conteact-panel {
                position: absolute;
                top: 100%;
                /*   right: 20%;*/
                left: 0;
                display: block;
                padding: 1em 0;
                background-color: rgba(0, 0, 0, 0.6);
                transition: padding .3s, height .3s;
                width: 350px;
                z-index: 10;
                margin-top: 0.2em;
                width: -webkit-max-content;
                width: max-content;
            }

                #SITE_CONTENT > .s-header .header-contact .conteact-panel.panel {
                    padding: 0;
                    height: 0;
                    overflow: hidden;
                }

                #SITE_CONTENT > .s-header .header-contact .conteact-panel a:hover {
                    color: #84ee9b;
                }

        #SITE_CONTENT > .s-header .language-toggler {
            color: inherit;
            font-size: 0.9em;
        }

            #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper {
                height: auto;
                padding: 2px 7px;
                line-height: 1;
                color: inherit;
                background-color: transparent;
                border-color: #ffffff;
                border-width: 0;
            }

                #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper:not(:first-child)::before {
                    background-color: #ffffff;
                }

                #SITE_CONTENT > .s-header .language-toggler > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
                    color: #84a94a;
                }

        #SITE_CONTENT > .s-header .header-nav {
            display: flex;
            justify-content: end;
            margin: 0 -5px;
        }

            #SITE_CONTENT > .s-header .header-nav > .nav-col {
                display: block;
                padding: 0 5px;
            }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin: 0;
            padding: 0 1em;
            height: 1.825em;
            background: #94b796;
            background: linear-gradient(to right, #94b796 35%, #58827b 100%);
            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#94b796', endColorstr='#58827b', GradientType=1);
            border-radius: 2em;
        }

        #SITE_CONTENT > .s-header .menu > li {
            position: relative;
            height: 100%;
        }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) > li + li {
            margin-left: 1em;
        }

        #SITE_CONTENT > .s-header .menu:not(.menu-sub) > li:hover::before {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1em;
            right: -1em;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2em;
        }

        #SITE_CONTENT > .s-header .menu a {
            z-index: 1;
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            color: #ffffff;
            font-size: 0.9em;
        }

            #SITE_CONTENT > .s-header .menu a > .link-icon {
                margin-right: 5px;
                width: 1em;
            }

                #SITE_CONTENT > .s-header .menu a > .link-icon > img {
                    max-width: 1em;
                    max-height: 1em;
                }

            #SITE_CONTENT > .s-header .menu a > .link-label {
                flex-basis: 100%;
            }

            #SITE_CONTENT > .s-header .menu a > .link-caret {
                padding-left: 5px;
            }

            #SITE_CONTENT > .s-header .menu a.active {
                color: #255058;
                font-weight: 600;
            }

        #SITE_CONTENT > .s-header .nav-btn {
            display: inline-block;
            padding: 0.35em 1em;
            border-width: 0;
            border-radius: 2em;
            outline: none;
        }

        #SITE_CONTENT > .s-header .btn-default {
            color: #255058;
            background-color: #ffffff;
        }

        #SITE_CONTENT > .s-header .btn-search {
            color: #ffffff;
            background-color: #84a94a;
        }

    #SITE_CONTENT > .s-body {
        position: relative;
        min-height: calc(100vh - 80px);
        background-color: #ffffff;
        background-image: url(/static/media/bg-main.c47e9f73.png);
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }

    #SITE_CONTENT > .s-footer {
        position: relative;
        display: block;
    }

        #SITE_CONTENT > .s-footer > .footer-subscribe {
            display: block;
            padding: 1.5em 0;
            background-color: #89a9ac;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap {
            display: block;
        }

            #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap > .content-private > .private-title {
                    color: #ffffff;
                    margin-right: 10px;
                }

                #SITE_CONTENT > .s-footer > .footer-sitemap .panel-sitemap > .content-private > .private-desc {
                    color: #ffffff;
                    padding-left: 10px;
                    border-left: 1px solid rgba(255, 255, 255, 1);
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: flex-end;
                padding: 0.75em 5vw;
                color: #ffffff;
                background-color: #255058;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header:hover {
                    background-color: #1a6b5e;
                }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-header .ant-collapse-arrow {
                    margin-left: 7.5em;
                    margin-right: 0;
                    position: absolute;
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-content {
                background: #219583;
                background: linear-gradient(to right, #219583 0%, #50b6a6 100%);
                filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#219583', endColorstr='#50b6a6', GradientType=1);
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .ant-collapse-content > .ant-collapse-content-box {
                    padding: 1.5em 2em 3em;
                }

            #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body {
                display: flex;
                flex-wrap: wrap;
                padding: 0 5vw;
            }

                #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
                    flex-basis: 15%;
                    padding: 1em 0;
                }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(6n+6)) {
                        margin-right: 2%;
                    }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu a {
                        display: flex;
                        align-items: center;
                        color: #ffffff;
                    }

                        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu a:hover {
                            color: rgba(255, 255, 255, 0.5);
                            text-decoration: none;
                        }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-head {
                        font-weight: bold;
                    }

                    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-item {
                        margin-top: 0.5em;
                    }

                        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu > .menu-item:before {
                            content: '-';
                            display: block;
                            padding-right: 0.5em;
                        }

@media(max-width: 1199px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 18%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(6n+6)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(5n+5)) {
            margin-right: 2.5%;
        }
}

@media(max-width: 991px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 24.25%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(5n+5)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(4n+4)) {
            margin-right: 1%;
        }

    #SITE_CONTENT > .s-header .header-contact {
        font-size: 0.8em;
    }
}

@media(max-width: 800px) {
    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
        font-size: 1.15em;
    }
}

@media(max-width: 770px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 32%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(4n+4)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(3n+3)) {
            margin-right: 2%;
        }

    #SITE_CONTENT > .s-header .header-contact {
        font-size: 0.7em;
        /* display: none; */
    }

        #SITE_CONTENT > .s-header .header-contact > .contact-item .contact-item-icon {
            margin: 0 2px;
        }

    /* #SITE_CONTENT > .s-header .header-contact  .conteact-panel {
            position: absolute;
            top: 100%;
            right: 55%;
            display: block;
            padding: 1em 0;
            background-color: rgba(0, 0, 0, 0.6);
            box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
            transition: padding .3s, height .3s;
            width: 50%;
            z-index: 10;
        }*/
}

@media(max-width: 575px) {
    #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu {
        flex-basis: 47%;
    }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(3n+3)) {
            margin-right: 0;
        }

        #SITE_CONTENT > .s-footer > .footer-sitemap .sitemap-body > .sitemap-menu:not(:nth-of-type(2n+2)) {
            margin-right: 6%;
        }

    /* #SITE_CONTENT > .s-header .header-contact {
            display: none;
        } */
    #SITE_CONTENT > .s-header .header-contact > .contact-us {
        display: block;
    }

    #SITE_CONTENT > .s-header .header-contact > .contact-item {
        display: none;
    }
}

@media(max-width: 415px) {
    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-1 {
        font-weight: 500;
        font-size: 1.1em;
    }

    #SITE_CONTENT > .s-header > .header-title > .title-label > .label-2 {
        font-weight: 300;
        font-size: 1em;
    }
}

#SITE_CONTENT > .s-footer .footer-content {
    display: flex;
    background-color: #ffffff;
    height: 70px;
}

    #SITE_CONTENT > .s-footer .footer-content > .content-institution {
        padding: .5em 2em 1.5em;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution img {
            margin: 5px 15px;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-ptt {
            height: 2em;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-institution {
            height: 3em;
        }

    #SITE_CONTENT > .s-footer .footer-content > .content-private {
        padding: 1.45em 2em 1.5em;
        margin-left: auto;
        display: block;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-private > .private-title {
            font-size: 0.9em;
            color: #00a651;
            display: block;
        }

        /* #SITE_CONTENT>.s-footer .footer-content>.content-private >.private-title:after {
        content: "";
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: 0;
        right: -4px;
        box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 1);
        z-index: 10;
    } */

        #SITE_CONTENT > .s-footer .footer-content > .content-private > .private-desc {
            /* margin-left: 10px; */
            font-size: 0.9em;
            color: #00a651;
            display: block;
        }

    #SITE_CONTENT > .s-footer .footer-content > .content-contact {
        padding: 1.5em 2em 3em;
        margin-left: auto;
        text-align: right;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-contact > .contact-title {
            color: #299b89;
            font-size: 0.9em;
            font-weight: 500;
        }

        #SITE_CONTENT > .s-footer .footer-content > .content-contact > .contact-address {
            color: #495a72;
            font-size: 0.8em;
        }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel {
        display: none;
    }

@media (max-width: 991px) {
    #SITE_CONTENT > .s-footer .footer-content {
        flex-direction: column;
    }

        #SITE_CONTENT > .s-footer .footer-content > .content-institution {
            padding: 1em 2em;
            /* text-align: center; */
        }

    /* #SITE_CONTENT > .s-footer .footer-content > .content-contact {
            margin-left: 0;
            padding: 1em 2em;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-left-width: 0;
        } */
    /* #SITE_CONTENT>.s-footer .footer-content>.content-private {
            display: none;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .content-contact {
            display: none;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel {
            display: inline-flex;
            margin-left: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-left-width: 0;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private {
            padding: 2em 1.5em 2em 2em;
            font-size: 0.9em;
            width: 50%;
            display: block;
            align-items: center;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-title {
            position: relative;
            display: block;
            color: #00a651;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-title:after {
            content: "";
            position: absolute;
            top: -8px;
            bottom: -8px;
            left: 0;
            right: -4px;
            box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 1);
            z-index: 10;
        }

        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private > .private-desc {
            color: #00a651;
            display: block;
        }
    
        #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact {
            width: 50%;
            padding: 2em 1.5em 2em 2em;
            text-align: right;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    
            #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-title {
                color: #299b89;
                font-size: 0.9em;
                font-weight: 500;
            }
            #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-address {
                color: #495a72;
                font-size: 0.8em;
            } */
}

@media (max-width: 575px) {
    #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-ptt {
        height: 1em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .content-institution .ico-institution {
        height: 2em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .contact-private {
        font-size: 0.75em;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-title {
        color: #299b89;
        font-size: 0.8em;
        font-weight: 500;
    }

    #SITE_CONTENT > .s-footer .footer-content > .contact-panel > .content-contact > .contact-address {
        color: #495a72;
        font-size: 0.7em;
    }
}

#SITE_CONTENT > .s-block {
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    cursor: pointer;
    transition: opacity .3s, visibility .4s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s, visibility .4s;
    transition: transform .3s, opacity .3s, visibility .4s, -webkit-transform .3s;
}

    #SITE_CONTENT > .s-block .block-label {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 1.5em;
        color: #ffffff;
        opacity: 0.5;
    }

    #SITE_CONTENT > .s-block:hover .block-label {
        opacity: 1;
    }

#SITE_NAV {
    z-index: 30;
    position: fixed;
    top: 0;
    right: -270px;
    bottom: 0;
    outline: 0;
    width: 270px;
    max-width: 270px;
    background: #94b796;
    background: linear-gradient(to bottom, #94b796 35%, #58827b 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#94b796', endColorstr='#58827b', GradientType=0);
    overflow: hidden;
    transition: -webkit-transform ease 0.3s;
    transition: transform ease 0.3s;
    transition: transform ease 0.3s, -webkit-transform ease 0.3s;
}

    #SITE_NAV .menu {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 270px;
        list-style-type: none;
        padding: 0;
        margin: 0;
        transition: -webkit-transform ease 0.2s;
        transition: transform ease 0.2s;
        transition: transform ease 0.2s, -webkit-transform ease 0.2s;
    }

        #SITE_NAV .menu li {
        }

        #SITE_NAV .menu a {
            position: relative;
            display: flex;
            padding: 12px 15px;
            font-size: 1em;
            color: #ffffff;
            transition: color .2s;
        }

            #SITE_NAV .menu a > .link-icon {
                margin-right: 5px;
                width: 1em;
            }

                #SITE_NAV .menu a > .link-icon > img {
                    max-width: 1em;
                    max-height: 1em;
                }

            #SITE_NAV .menu a > .link-label {
                flex-basis: 100%;
            }

            #SITE_NAV .menu a > .link-caret {
                padding-left: 5px;
            }

            #SITE_NAV .menu a:hover {
                background-color: rgba(37, 80, 88, 0.5);
                text-decoration: none;
            }

            #SITE_NAV .menu a.active {
                background-color: #255058;
            }

            #SITE_NAV .menu a.link-back {
                font-weight: 400;
            }

    #SITE_NAV ul.menu-sub {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        left: 100%;
        display: none;
        width: 100%;
    }

    #SITE_NAV a.menu-current + ul.menu-sub {
        display: block;
    }

@media (max-width: 1199px) {
    .nav-expanded {
        overflow: hidden;
    }

        .nav-expanded #SITE_NAV {
            -webkit-transform: translate3d(-270px, 0, 0);
            transform: translate3d(-270px, 0, 0);
        }

        .nav-expanded #SITE_CONTENT > .s-block {
            visibility: visible;
            opacity: 0.75;
            -webkit-transform: translate3d(-270px, 0, 0);
            transform: translate3d(-270px, 0, 0);
            transition: opacity .3s, -webkit-transform .3s;
            transition: transform .3s, opacity .3s;
            transition: transform .3s, opacity .3s, -webkit-transform .3s;
        }
}



#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li > .menu-sub {
    display: none;
    position: absolute;
    /*    top: 150%;*/
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #e3fbf5 !important;
    list-style-type: none;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 0;
    width: 350px;
    height: auto;
    border-radius: 0 !important;
    font-size: 1em !important;
}


#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li:hover > .menu-sub {
    display: block;
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu:not(.menu-sub) > li:hover > .menu-sub:before {
        content: '';
        display: block;
        top: -15px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        /*        border-top: 15px solid #779D89;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;*/
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub li {
    /*Gky border-bottom: 1px solid rgba(0, 0, 0, 0.15);*/
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub li + li {
        /*Gky border-top: 1px solid rgba(0, 0, 0, 0.1);*/
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a {
    position: relative;
    /*Gky  font-weight: normal;*/
    /*padding: 20px 30px;*/
    text-transform: capitalize;
    white-space: unset;
    color: #255058;
    font-weight: bold; /*Gky*/
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a.active {
        background-color: #219583;
        color: #ffffff;
    }


#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu-sub li:hover {
    display: block;
    /*   background-color: red !important;*/
}

/*#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li:hover:before {
    content: none;
}*/

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a {
    padding: .5rem;
}

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub a:hover {
        background-color: #d0ebd3;
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub {
    /*top: 0;
    left: 100%;*/
    position: relative;
    border-radius: 0 !important;
    display: block;
    height: 100%;
    padding-left: 1rem;
    margin-bottom: .5rem;
}



    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub a {
        font-weight: normal;
    }

    #PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .header-nav .menu li > .menu-sub .menu-sub li {
        border-bottom: 1px solid lightgrey;
    }

#PAGE_MAIN > #SITE_CONTENT > .s-header .header-content .menu:not(.menu-sub) > li > .menu-sub {
    padding: 1rem !important;
}

.menu-sub .menu-sub {
    list-style: none; /* Remove default bullets */
}

    .menu-sub .menu-sub li::before {
        content: ""; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: green; /* Change the color */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: .25rem;
        height: .25rem;
        border-radius: 100%;
        background-color: green;
    }

#PAGE_ADMIN #SITE_CONTENT {
  position: relative;
  min-height: 100vh;
  padding-bottom: 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header {
  width: 100%;
  position: fixed;
  /*top: 25px;*/
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 999;
  /* padding: 10px 20px; */
  padding: 0px 20px;
  justify-content: space-between;
  box-shadow: 0px 5px 5px -7px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(/static/media/91250.5f4f3a3d.jpg);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right,  rgba(255,255,255,1) 25%,rgba(125,185,232,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 );


}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #ffffff;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 15px;
  margin-right: 15px;
  z-index: 10;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.5);
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title {
  float: left;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web {
  position: relative;
  bottom: 4px;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
  width: 80px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
  line-height: 1;
  margin-left: 15px;
  color: #000000;
  display: flex;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
  font-size: 1.3em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
    font-size: 1.75em;
    margin-left: 7px;
    color: #015d26;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-shadow: 1px 1px 2px #015d26;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
  padding-right: 12px;
  line-height: 1.1;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
  /* color: #000000; */
  color: #ffffff;
  z-index: 10;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
  font-size: 0.8em;
  color: #666666;
}
#PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
  white-space: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body {
  position: relative;
  display: flex;
  /*padding-top: 60px;*/
  /* background-color: rgba(223, 223, 223, 0.2); */
  min-height: calc(100vh - 30px);
  /* min-height: 100vh; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side {
  position: relative;
  display: block;
  flex: 0 0 240px;
  max-width: 240px;
  transition: all .3s;
  background-image: url(/static/media/aerial-view-green-forest.93d5c428.jpg);
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 99;
  /* background-color: #edf8ff; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0, 0.2);
}
/* #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.5);
} */
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav {
  z-index: 1;
  position: fixed;
  top: 50px;
  left: 0;
  /* width: 235px; */
  width: 240px;
  max-height: calc(100vh - 155px);
  padding: 5px 0;
  transition: width .3s;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
  position: relative;
  height: calc(100vh - 90px);
  /* padding-right: 7px; */
  overflow: hidden;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu {
  position: relative;
  display: block;
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 0;
  padding-left: 0;
  /* padding-left: 15px; */
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu li+li {
  margin-top: 10px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a {
  position: relative;
  display: flex;
  /* padding: 10px; */
  font-size: 1em;
  line-height: 1;
  /* color: #424949; */
  color: #ffffff;
  align-items: center;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-icon {
  /* display: block;
  flex-basis: 1.8em;
  min-width: 1.8em;
  width: 1.8em;
  height: 1.8em;
  font-size: 1.2em;
  line-height: 1.8em;
  text-align: center;
  color: #ffffff; */
  display: block;
  min-width: 50px;
  padding: 13px 0;
  font-size: 1.357142857142857em;
  line-height: 1;
  text-align: center;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-icon>i {
  line-height: inherit;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label {
  display: block;
  flex-basis: 100%;
  margin-left: 15px;
  padding: 0.25em 0;
  transition: all .3s;
  line-height: 1.3;
  /* font-size: 0.9em; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label .labe-leng {
  font-size: 1em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-label .label-thai {
  font-size: 0.8em;
}
/* #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.line:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  box-shadow: inset -1px 0px 0px 0px rgb(0 0 0 / 50%);
} */
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a>.link-caret {
  font-size: 0.85em;
  line-height: 2em;
  padding-right: 15px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.6);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.active {
  /* color: #000000;
  background-color: #ffffff;
  box-shadow: inset 5px 0px 0px 0px rgb(111, 215, 210, 1); */
  /* padding: 15px 10px; */
  background-color: rgba(0, 0, 0, 0.6);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.active>.link-icon {
  /* color: #6fd7d2; */
  width: 50px;
  background-color: #8ec549;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu .menu-sub {
  position: absolute;
  top: 0;
  list-style-type: none;
  display: none;
  margin: 0;
  left: 100%;
  width: 100%;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu a.menu-current+.menu-sub {
  display: block;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main {
  display: block;
  flex-grow: 1;
  /* padding: 20px 15px; */
  padding: 20px 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head {
  justify-content: space-between;
  /* display: flex; */
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}
/*#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag {
  padding-top: 15px;
}*/
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag>.flag-item {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-icon {
  display: block;
  color: #141e46;
  font-size: 1.2em;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-title {
  display: block;
  font-size: 1.2em;
  color: #273746;
  padding-left: 10px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-body {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 30px;
  background-color: #417c4e;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel {
  display: flex;
  width: 100%;
  height: 30px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content {
  padding: 8px 20px 7px 25px;
  line-height: 1;
  text-align: end;
  width: 100%;
}
#PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content>.footer-title {
  color: #fcfcff;
  font-size: 0.8em;
}
#PAGE_ADMIN #MENU_SWITCHER {
  z-index: 99;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 30px;
  line-height: 30px;
  font-size: 1.1em;
  text-align: center;
  border-width: 0;
  border-radius: 50em;
  background-color: transparent;
  outline: none;
}
#PAGE_ADMIN #MENU_SWITCHER:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse {
  flex: 0 0 50px;
  max-width: 50px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav {
  /* width: 65px; */
  width: 50px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .nav-scroller {
  overflow: visible;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu {
  /* padding-left: 7px; */
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu li {
  height: 45px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a>.link-label {
  display: none;
  white-space: nowrap;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a>.link-caret {
  font-size: 0.6em;
  margin-left: 1px;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a.menu-current {
  display: none;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover {
  position: absolute;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover>.link-label {
  display: block;
}
#PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse>.main-nav .menu a:hover>.link-caret {
  font-size: 0.85em;
  line-height: 2em;
  margin-left: 10px;
}

@media (max-width: 991px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-body {
      display: block;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side {
    background-image: url(/static/media/aerial-view-green-forest.93d5c428.jpg);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.2);
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav {
      /* top: 80px; */
      padding: 0;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu {
      /* padding: 10px 15px; */
      /* background-color: rgba(0, 0, 0, 0.85); */
      background-image: url(/static/media/aerial-view-green-forest.93d5c428.jpg);
      background-position-x: center !important;
      background-position-y: top !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-attachment: fixed;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      min-height: calc(100vh - 30px);
      margin-top: 5px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .menu:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.2);
}
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
      height: auto;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side.nav-collapse {
      display: none;
  }
}

@media (max-width: 815px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-side>.main-nav .nav-scroller {
      height: calc(100vh - 90px);
  }
}

@media (max-width:768px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 30px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
    font-size: 0.95em;
    margin-left: 0;
}
}

@media (max-width:575px) {
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
      height: 55px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
      width: 60px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 0px;
      padding-left: 0px;
      display: block;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.95em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.8em;
      margin-left: 0;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 7px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
      padding-right: 15px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.9em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.8em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
      color: #666666;
      font-size: 1em;
  }
}

@media (max-width:415px) {
  /* #PAGE_ADMIN #SITE_CONTENT {
      position: absolute;
  } */
  #PAGE_ADMIN #SITE_CONTENT>.content-header {
      padding: 0px 2px;
      position: absolute;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item {
      height: 55px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.logo-web img {
      width: 40px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 0px;
      padding-left: 4px;
      margin-left: 7px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.8em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.75em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info {
      padding-right: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail {
      padding-right: 4px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.65em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
      color: #666666;
      font-size: 0.85em;
      /* display: block; */
      display: flex;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool > .btn-logout {
      margin-top: 5px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
      padding-right: 3px;
      margin-right: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main {
      padding: 20px 15px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-body>.body-main>.main-head .head-flag {
      font-size: 0.9em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content {
    padding: 10px 20px 7px 25px;
}
  #PAGE_ADMIN #SITE_CONTENT>.content-footer .footer-panel>.footer-content>.footer-title {
    color: #fcfcff;
    font-size: 0.6em;
  }

}
@media (max-width:376px) {
  #PAGE_ADMIN #SITE_CONTENT {
    position: relative;
}
  #PAGE_ADMIN #SITE_CONTENT>.content-header {
    padding: 0px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title {
      padding-top: 2px;
      padding-left: 0px;
      margin-left: 10px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-1 {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-title>.title>.title-2 {
      font-size: 0.7em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-title {
      font-size: 0.7em;
      color: #000000;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-detail>.info-desc {
      font-size: 0.65em;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-menu_switcher {
    padding-right: 0px;
  }
  #PAGE_ADMIN #SITE_CONTENT>.content-header .header-item .main-info .info-tool {
    display: grid;
  }
}

#PAGE_ADMIN .fileuploader {
  padding: 0;
  margin: 0;
}
#PAGE_ADMIN .navTabList .nav-link {
  color: #666666;
}
#PAGE_ADMIN .navTabList .nav-link:hover {
  cursor: pointer;
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
#PAGE_ADMIN .navTabList .nav-link.active {
  box-shadow: inset 0px 2px 0px 0px #0d6efd;
  color: #141e46;
}
#PAGE_ADMIN .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  padding: 10px 15px;
}
.fileuploader {
    font-family: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
}

.fileuploader-items .fileuploader-item {
    padding: 10px 20px !important;
}

.react-datepicker {
    font-family: inherit !important;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.modal-dialog {
    position: absolute !important;
    top: 45% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    width: 100% !important;
}

.modal-dialog {
    position: absolute !important;
    top: 40% !important;
    max-height: 80vh;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    width: 100% !important;
}

.form-check-inline .form-check-input {
    margin-right: 10px;
}

.esri-popup__inline-actions-container {
    display: none;
}

.esri-popup__footer {
    display: none !important;
}
.swal2-border-radius {
    border-radius: 50px !important;
}

#PAGE_INTRO {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#PAGE_INTRO>.intro-cover {
    flex-grow: 1;
    display: block;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_INTRO>.intro-bar {
    flex-basis: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4cb0a1;
    border-top: 5px solid #ffffff;
}

#PAGE_INTRO>.intro-bar>a {
    display: block;
    padding: 0.5em 2em;
    margin: 10px 20px;
    text-transform: uppercase;
    color: #234d1f;
    background-color: #ffffff;
    border-radius: 2em;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    transition: color .3s, background-color .3s;
    width: 200px;
    text-align: center;
}

#PAGE_INTRO>.intro-bar>a:hover {
    color: #ffffff;
    background-color: #94b796;
}
#PAGE_HOME {
    position: relative;
    display: block;
    background-color: #ffffff;
    background-image: url(/static/media/bg-main.c47e9f73.png);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: calc(100vh - 80px);
}

#PAGE_HOME::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

#PAGE_HOME>.home-banner {
    z-index: 1;
    position: relative;
    height: calc(100vh - 80px);
    max-height: calc(100vw * 9 / 16);
}

#PAGE_HOME>.home-banner>.logo-reforest {
    z-index: 1;
    position: absolute;
    top: 10%;
    left: 10%;
    display: block;
    width: 12.5vw;
    height: 12.5vw;
    background-image: url(/static/media/logo-reforest.37075e82.png);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_HOME>.home-banner>.slick-slider {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.25);
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track {
    height: 100%;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide {
    position: relative;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* #PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #229684; */
    /* background: -moz-linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(45deg, rgba(34, 76, 21, 1) 0%, rgba(167, 183, 161, 0) 60%, rgba(255, 255, 255, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#224c15', endColorstr='#00ffffff', GradientType=1);
} */

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-content {
    z-index: 1;
    position: absolute;
    top: calc(10% + 14vw);
    bottom: calc(10% + 8vw);
    left: 10%;
    /* right: 50%; */
    display: block;
    font-size: 2vw;
    line-height: 1;
    color: #ffffff;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-link {
    z-index: 2;
    position: absolute;
    left: 5%;
    bottom: calc(5% + 0vw);
    /* bottom: calc(10% + 4vw); */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0.8em;
    font-size: 1vw;
    color: #ffffff;
    background-color: #3da393;
    border-radius: 35px;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-list>.slick-track>.slick-slide .slick-item>.banner-link:hover {
    background-color: #346460;
    text-decoration: none;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow {
    z-index: 2;
    top: auto;
    left: 93%;
    bottom: 5%;
    -webkit-transform: none;
            transform: none;
    width: auto;
    height: auto;
    font-weight: bold;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow::before {
    font-size: 2vw;
    font-family: IcoFont;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 50%;
    opacity: 1;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow:hover::before {
    opacity: 0.75;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-prev {}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-prev::before {
    content: "\eac9";
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-next {
    margin-left: 3vw;
}

#PAGE_HOME>.home-banner>.slick-slider>.slick-arrow.slick-next::before {
    content: "\eaca";
}

#PAGE_HOME>.home-news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 3vw 5vw 1vw 5vw;
}

#PAGE_HOME>.home-news>.news-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #519f39;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.home-news>.news-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.news-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.home-news>.news-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.home-news>.news-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.home-news>.news-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.home-news>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    background: #229684;
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
    color: #ffffff;
    font-size: 1.2em;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title-first {
    display: flex;
    padding: 0.25em 0.75em 0.25em 0em;
    color: #ffffff;
    /* border-left: 1px solid #ffffff; */
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link>.link-label {}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link>.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link:hover>.link-label {
    text-decoration: underline;
}

#PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-link:hover>.link-icon {
    color: #229684;
    background-color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel {
    display: flex;
    flex-direction: column;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    background: #229684;
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-title-first {
    display: flex;
    padding: 0.25em 0.75em 0.25em 0em;
    color: #ffffff;
    /* border-left: 1px solid #ffffff; */
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #ffffff;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link>.link-label {}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link>.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link:hover>.link-label {
    text-decoration: underline;
}

#PAGE_HOME>.home-news>.map-panel>.panel-head>.panel-link:hover>.link-icon {
    color: #229684;
    background-color: #ffffff;
}

    #PAGE_HOME > .home-news > .map-panel > .panel-body-content {
        margin-top: 1.5vw;
        background-color: #ffffff;
    }

#PAGE_HOME>.news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 1.5vw 5vw 1vw 5vw;
}

#PAGE_HOME>.news>.news-content {
    padding: 0 6vw;
}

#PAGE_HOME>.news>.news-content>.content {
    /* background-color: rgba(37, 80, 88, 0.2); */
    background-color: #ffffff;
    padding: 1em 2.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-detail {
    font-size: 0.95em;
    margin: 1em 0;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-img {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0.5em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
    height: calc((50vh - 70px) * 0.5);
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

#PAGE_HOME>.news>.news-content>.content>.news-item>.item-box>.item-cover {
    flex-basis: 100%;
    background-color: #eeeeee;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1em;
}

#PAGE_HOME>.news>.news-content>.content>.news-ref {
    margin-bottom: 1.2em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-ref>.ref-title {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-file {
    margin-bottom: 0.5em;
    line-height: normal;
}

#PAGE_HOME>.news>.news-content>.content>.news-file>.file-title {
    color: #346460;
    font-size: 1.1em;
    font-weight: 600;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.3em 1em;
    color: #ffffff;
    background: #e4ab4a;
    justify-content: center;
}

#PAGE_HOME>.news>.news-content>.content>.news-related>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    text-align: center;
}

#PAGE_HOME>.row-date {
    z-index: 1;
    position: relative;
    padding: 0 5vw 1vw 5vw;
}

#PAGE_HOME>.row-date>.date-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.row-date>.date-panel>.news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.news>.news-content>.content>.news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.search {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 0vw 5vw 1vw 5vw;
}

#PAGE_HOME>.search>.search-content {
    padding: 0 6vw;
}

#PAGE_HOME>.search>.search-content>.content {
    /* background-color: rgba(37, 80, 88, 0.2); */
    background-color: #ffffff;
    padding: 0em 1.2em 1em 1.2em;;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body-content > .news-date {
    font-size: 0.8em;
    color: #898989;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

#PAGE_HOME > .home-news > .news-panel > .panel-body-content {
    /*display: flex;*/
    margin-top: 1.5vw;
    /*height: 16vw;*/
    /*background-color: rgba(37, 80, 88, 0.2);*/
    /*-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}


#PAGE_HOME > .home-news > .news-panel > .panel-body-content > .news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    /*background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item {
    position: relative;
    display: block;
    flex-basis: 24%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-body>.news-item>.news-date, #PAGE_HOME>.home-news>.news-panel>.panel-body-transparent>.news-item>.news-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:hover::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:hover::after {
    opacity: 0.25;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary {
    flex-basis: 31%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary > .news-date, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary > .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary:hover::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item:last-of-type {
    margin-right: 1%;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        height: 18.5vw;
    }
    #PAGE_HOME>.home-news>.map-panel {
        padding: 0;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        height: 20vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-news {
        /* padding: 3vw; */
        padding: 7vw 3vw 3vw 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
        margin: 1.5em 0 0 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item.news-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-body > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-body-transparent > .news-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME>.home-news>.map-panel>.panel-head {
        font-size: 0.8em;
        margin: 1.5em 0 0 0;
    }
}

@media(max-width: 575px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        /* display: block; */
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-head>.panel-title {
        padding: 1em 0.75em 1em 0.75em;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-head .search {
        margin-top: 10px;
    }

    #SITE_CONTENT > .s-header > .header-search {
        width: 100%;
    }
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box {
    display: flex;
    margin: 1.2vw 0;
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag {
    position: relative;
    display: block;
    padding: 1.5em 0.6em;
    font-size: 1em;
    line-height: 1;
    text-align: center;
    width: 147px;
    /* flex-basis: 20%; */
    height: 4.5vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 60%);
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #000000;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title {
    position: relative;
    color: #000000;
    font-weight: bold;
    background-color: #F0FFF0;
    padding: 5px 0px;
}

/* #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag + .box-flag {
    margin-left: 15px;
} */

#PAGE_HOME>.home-history {
    z-index: 1;
    position: relative;
}

#PAGE_HOME>.home-history>img {
    width: 100%;
}

#PAGE_HOME>.home-calendar {
    z-index: 1;
    position: relative;
    padding: 3vw 5vw;
}

#PAGE_HOME>.home-calendar>.calendar-box {
    display: flex;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #239684;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    color: #000000;
    padding: 0.5em 0;
    margin-left: auto;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.2);
    /* padding: 0.8em 0; */
    height: 60px;
    width: 20%;
    margin-left: auto;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button> li+li {
    margin-left: 1em;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button a {
    display: flex;
    align-items: center;
    height: 100%;
    /* padding: 0.25em 1.5em; */
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-img {
    position: relative;
    align-items: center;
    display: block;
    /* flex-basis: 1.8em; */
    /* min-width: 25%; */
    font-size: 1.2em;
    line-height: 1.8em;
    text-align: center;
    color: #ffffff;
    width: 3.5em;
}

/* #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-title:after {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 5px 0 5px -7px rgba(0, 0, 0, 0.75);
} */

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-img>img {
    /* width: 35px;
    height: 35px; */
    width: 105%;
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button .button-title {
    display: flex;
    vertical-align: middle;
    flex-basis: 100%;
    align-items: center;
    /* padding-top: 1em; */
    padding-left: 0.5em;
    transition: all .3s;
    line-height: 1.3;
    height: 100%;
    border-left: 1px solid rgba(220, 215, 215, 0.75);
    font-size: 14px;
    /* overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    max-width: 230px; */
    /*width: 220px; */
}

#PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li.active .button-title {
    background-color: #84a94a;
    color: #ffffff;
}

#PAGE_HOME>.home-calendar>.calendar-panel {
    display: block;
    margin-top: 1.5vw;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity {
    position: relative;
    display: block;
    cursor: pointer;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
    top: calc(22.5vw * 3 / 4 * 0.5);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev:before,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next:before {
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item {
    position: relative;
    display: block;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
    position: relative;
    display: block;
    height: calc(22.5vw * 3 / 4);
    background-color: #bbbbbb;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover>.activity-date {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 5em;
    height: 5em;
    color: #ffffff;
    background-color: rgba(35, 150, 132, 0.5);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover>.activity-date::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 2em solid rgba(35, 150, 132, 0.5);
    border-right: 5em solid transparent;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
    padding: 1em 0.75em;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.3;
    max-height: 2.5em;
    overflow: hidden;
    padding-right: 0.75em;
    font-weight: 500;
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-caret {
    position: relative;
    display: block;
    width: 2.5em;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-title>.title-caret::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item:hover>.activity-cover::after {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(35, 150, 132, 0.15);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item:hover>.activity-title>.title-caret::before {
    color: #ffffff;
    background-color: #37a08f;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date {
    display: block;
    padding: 1.5vw 1vw;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-prev {
    left: 0;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-next {
    right: 0;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-prev:before,
#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-next:before {
    color: #239684;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    margin: 0 auto;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    color: #239684;
    font-weight: 500;
    border-radius: 50%;
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link:hover {
    background-color: rgba(35, 150, 132, 0.15);
}

#PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .date-item>.date-link.active {
    color: #ffffff;
    background-color: #239684;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-date .slick-slider {
        padding: 0 2.5em;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-calendar>.calendar-title {
        font-size: 0.9em;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box >.b-box .box-flag .flag-title {
        font-size: 0.7em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-calendar>.calendar-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
        top: calc(30vw * 3 / 4 * 0.5);
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
        height: calc(30vw * 3 / 4);
    }
}

@media(max-width: 575px) {
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-prev,
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .slick-next {
        top: calc(45vw * 3 / 4 * 0.5);
    }
    #PAGE_HOME>.home-calendar>.calendar-panel>.calendar-activity .activity-item>.activity-cover {
        height: calc(45vw * 3 / 4);
    }
}

#PAGE_HOME>.home-booking {
    z-index: 1;
    position: relative;
    padding: 3vw 5vw;
}

#PAGE_HOME>.home-booking>.booking-head {
    display: flex;
    justify-content: center;
}

#PAGE_HOME>.home-booking .booking-title {
    display: block;
    margin: 0 auto;
    padding: 0.25em;
    min-width: 60%;
    text-align: center;
    font-size: 2.5vw;
    color: #239684;
    border-top: 1px solid #239684;
    border-bottom: 1px solid #239684;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots {
    bottom: 0;
    right: 0;
    display: flex !important;
    width: auto;
    padding: 1.5vw;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li>button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li>button::before {
    content: none;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li:hover>button {
    opacity: 0.5;
}

#PAGE_HOME>.home-booking .booking-slider>.slick-dots>li.slick-active>button {
    opacity: 1;
}

#PAGE_HOME>.home-booking .booking-link {
    position: relative;
    display: block;
    height: 18vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.5vw;
    overflow: hidden;
}

#PAGE_HOME>.home-booking .booking-link::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border-radius: 1.5vw;
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME>.home-booking .booking-link:hover::after {
    opacity: 0.3;
}

#PAGE_HOME>.head-news {
    z-index: 1;
    position: relative;
    /* padding: 3vw 5vw; */
    padding: 0vw 5vw 1vw 5vw;
}

#PAGE_HOME>.head-news>.news-title {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: #519f39;
    padding: 0.5em 1.5em;
}

#PAGE_HOME>.head-news>.news-title>.title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

#PAGE_HOME>.head-news>.news-title>.title-label {
    margin-left: 1.5em;
    padding: 0.5em 1.5em;
    line-height: 1;
    border-left: 1px solid #ffffff;
}

#PAGE_HOME>.head-news>.news-title>.title-label>.title-secondary {
    font-weight: 500;
}

#PAGE_HOME>.head-news>.news-title>.title-label>.title-primary {
    font-size: 1.5em;
    font-weight: 300;
}

#PAGE_HOME>.head-news>.news-panel {
    display: flex;
    flex-direction: column;
    padding: 0 6vw;
    /* margin-top: 2.5vw; */
}

#PAGE_HOME>.head-news>.news-panel>.panel-head {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    color: #ffffff;
    background: #229684;
    background: linear-gradient(to right, #229684 0%, #51b6a6 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#229684', endColorstr='#51b6a6', GradientType=1);
}

#PAGE_HOME>.head-news>.news-panel>.panel-head>.panel-title {
    display: block;
    padding: 0.25em 0.75em;
    border-left: 1px solid #ffffff;
}

.ant-tooltip {
    max-width: 400px;
}

@media (max-width:1099px) {
    #PAGE_HOME>.home-calendar>.calendar-box {
        display: block;
    }
}

@media (max-width:768px) {
    #PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button {
        flex-wrap: wrap;
        margin-left: 0;
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
        flex-basis: 50%;
        width: 50%;
        margin-left: 0;
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button> li+li {
        margin-left: 0;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box {
        display: flex;
        width: 100%;
    }
    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 0.5em 0.6em;
        font-size: 0.7em;
    }
}

@media (max-width:575px) {
    #PAGE_HOME>.news>.news-content>.content>.news-item>.item-box {
        flex-basis: calc(100% / 1);
        max-width: calc(100% / 1);
    }

    #PAGE_HOME>.home-calendar>.calendar-box>.calendar-button li {
        flex-basis: 100%;
        width: 100%;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box {
        display: block;
        width: 100%;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 1.5em 0.6em;
        font-size: 0.9em;
        height: 13vw;
    }

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag+.box-flag {
        margin-left: 0px;
    }
}

@media (max-width:415px) {

    #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-box>.b-box .box-flag {
        padding: 1.1em 0.6em;
        font-size: 0.9em;
        height: 15vw;
    }
}






#PAGE_HOME .contents-index {
    z-index: 1;
    position: relative;
    /* padding: 1.5vw 0 1vw 0; */
    padding: 0 0 1vw 0;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);
}

/* #PAGE_HOME .contents-index>.index-contents {
    padding: 0 6vw;
} */

/* #PAGE_HOME .contents-index>.index-contents>.index {
    background-color: #ffffff;
    padding: 1em 1.2em;
} */



#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
    /*background-color: #ffffff;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);*/
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item {
    position: relative;
    display: block;
    flex-basis: 24%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .news-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        /* background-color: #bbbbbb; */
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
    z-index: 2;
    position: absolute;
    bottom: 1.2em;
    left: 0.5em;
    right: 0.5em;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.3;
    height: 3.5em;
    color: #000000;
    justify-content: center;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    /* background-color: #b5e8c2; */
    background-color: #94b796;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    transition: all .3s;
    padding: 0 0.5em;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover > .contents-cover {
    background-color: #dddddd;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover > .contents-title {
    background-color: #dddddd;
}

#PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body>.contents-item>.contents-date, #PAGE_HOME>.home-news>.news-panel .contents-index>.panel-body-transparent>.contents-item>.contents-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:hover::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:hover::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:hover::after {
    opacity: 0.2;
} */

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.news-primary, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary {
    flex-basis: 31%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary > .contents-date, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary > .contents-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary:hover::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary:hover::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:nth-of-type(3), #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:nth-of-type(3) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item:last-of-type, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item:last-of-type {
    /* margin-right: 1%; */
    margin-left: 1%;
}

@media(max-width: 1199px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        height: 18.5vw;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        height: 20vw;
    }
    /* #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        right: 2.5%;
    } */
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::before, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 768px) {
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item.contents-primary,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item,
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item.contents-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-cover, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item::after, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }

    #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body > .contents-item > .contents-title, #PAGE_HOME > .home-news > .news-panel .contents-index > .panel-body-transparent > .contents-item > .contents-title {
        left: 0em;
        right: 0em;
    }
}


#LOGIN {
    display: flex;
    /* flex-direction: row-reverse; */
    width: 100%;
    min-height: 100vh;
    align-items: center;
    color: #000000;
    background-image: url(/static/media/bg-login.8347ef4b.jpg);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

    #LOGIN::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #ffffff;
    }

    #LOGIN .box-overview {
        flex-basis: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column-reverse;
    }

        #LOGIN .box-overview > .box-cover {
            flex-basis: 100%;
            /*        background-image: url(assets/smart-agriculture-iot-with-hand-planting-tree-background.jpg);*/
            background-position-x: center;
            background-position-y: center;
            background-repeat: repeat;
            background-size: cover;
        }

    #LOGIN .box-login {
        flex-basis: calc(350px + 10vw);
        min-width: calc(350px + 10vw);
        max-width: 100%;
        position: absolute;
        left: 5%;
        padding: 0 5vw;
        transition: all .3s;
    }

        #LOGIN .box-login .box-title {
            line-height: 1;
         /*   text-transform: uppercase;*/
            /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); */
            margin: 10px 0;
        }

            #LOGIN .box-login .box-title.title-1 {
                font-size: 1.5em;
                font-weight: 500;
                color: white;
            }

            #LOGIN .box-login .box-title.title-2 {
                font-size: 1.8em;
                font-weight: 600;
            }

        #LOGIN .box-login .login-input {
            position: relative;
        }

            #LOGIN .box-login .login-input > i {
                position: absolute;
                padding: 15px;
                color: rgb(30, 25, 108, 0.89);
                width: 70px;
                text-align: left;
                font-size: 1.2em;
            }

            #LOGIN .box-login .login-input > input[type="text"],
            #LOGIN .box-login .login-input > input[type="password"] {
                display: block;
                width: 100%;
                padding: 10px 15px;
                padding-left: 3.3em;
                color: #333333;
                background-color: rgba(255, 255, 255, 0.9);
                border: 2px solid #ffffff;
                /* border-radius: 50em; */
                outline: none;
                font-size: 1em;
                line-height: 1.5;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                /* -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
                transition: background-color .3s;
            }

                #LOGIN .box-login .login-input > input[type="text"]:focus,
                #LOGIN .box-login .login-input > input[type="password"]:focus {
                    background-color: #ffffff;
                }

            #LOGIN .box-login .login-input > input::-webkit-input-placeholder {
                color: #999999;
            }

            #LOGIN .box-login .login-input > input::-webkit-input-placeholder,
            #LOGIN .box-login .login-input > input:-ms-input-placeholder,
            #LOGIN .box-login .login-input > input::placeholder {
                color: #999999;
            }

        #LOGIN .box-login button {
            /* padding: 10px 20px; */
            padding: 5px 20px;
            border-radius: 50em;
            outline: none;
            font-size: 1em;
            line-height: 1.5;
            border-width: 2px;
            border-style: solid;
        }

@media (max-width: 1199px) {
    #LOGIN .box-login {
        flex-basis: calc(300px + 10vw);
        min-width: calc(300px + 10vw);
    }

    #LOGIN .box-overview > .item-list {
        flex-wrap: wrap;
    }

        #LOGIN .box-overview > .item-list > .item-frame {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
            padding: 10px;
        }

            #LOGIN .box-overview > .item-list > .item-frame:nth-of-type(4n) {
                border-left: none;
            }

    #LOGIN .box-login .box-title.title-1 {
        font-size: 1.4em;
    }

    #LOGIN .box-login .box-title.title-2 {
        font-size: 2.2em;
    }
}

@media (max-width: 991px) {
    #LOGIN {
        justify-content: center;
    }

        #LOGIN .box-login {
            flex-basis: auto;
            padding: 0 15px;
            width: 400px;
            min-width: auto;
            max-width: 100%;
        }
}

@media (max-aspect-ratio: 1/1), (max-width: 991px) {
    #LOGIN .box-overview {
        display: none;
    }
}

@media (max-width: 575px) {
    #LOGIN {
        justify-content: center;
    }

        #LOGIN .box-login {
            flex-basis: auto;
            padding: 0 40px;
            width: 400px;
            min-width: auto;
            max-width: 100%;
        }
}


.modal-x .ant-modal-close-x {
    color: white !important;
}


#MAIN_MAP .list-province:hover {
    background-color: aliceblue;
    cursor: pointer;
}


#MAIN_MAP .scroll-data {
    display: block;
    height: 300px;
    position: relative;
}


    #MAIN_MAP .scroll-data .scroll-panel {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


#MAIN_MAP .box {
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    border: 1px solid black;
    border-radius: 10px;
}

#MAIN_MAP .new {
    background-color: #3ae4bb;
}

#MAIN_MAP .old {
    background-color: #ee9852;
}

#MAIN_MAP .other {
    background-color: #f96993;
}


#MAIN_MAP .esri-view-width-less-than-medium .esri-popup__main-container {
    width: 500px;
}




#MAIN_MAP .esri-view-height-less-than-medium .esri-popup__main-container {
    max-height: 300px;
}

#MAIN_MAP .map-list {
    border-radius: 20px;
    background-color: transparent;
}

    #MAIN_MAP .map-list .ant-list-item.list-province {
        border-radius: 20px;
        background-color: #effff4;
    }

        #MAIN_MAP .map-list .ant-list-item.list-province + .ant-list-item.list-province {
            margin: 12px 0;
        }

        #MAIN_MAP .map-list .ant-list-item.list-province:hover {
            background-color: #daece0;
        }



@media screen and (max-width: 1024px) {
    .mapcontainer {
        height: 300px !important;
    }
}


/*@media(max-width: 575px) {
    #Cookies_Policy .policy {
        min-height: calc(100vw * 15 / 6);
    }
}*/


/*#MAIN_MAP .icon-type1 {

    filter: hue-rotate(120deg);
}
*/

#CONTACT {
    position: relative;
    min-height: calc(100vh - 50px);
}

    #CONTACT .page-cover {
        position: relative;
        min-height: 100vh;
        /*     background-image: url(assets/bg-cover.jpg);*/
        background-attachment: fixed;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding: 85px 0 30px;
    }

        #CONTACT .page-cover .cover-content {
            position: relative;
            padding: 0 1vw;
            color: #ffffff;
            line-height: 1;
            text-align: right;
        }

            #CONTACT .page-cover .cover-content > .cover-title {
                font-size: 3.5em;
                font-weight: bold;
                text-transform: uppercase;
            }

                #CONTACT .page-cover .cover-content > .cover-title > svg {
                    height: 56px;
                }

            #CONTACT .page-cover .cover-content > .cover-desc {
                font-size: 1.2em;
                line-height: 1.1;
            }

@media (orientation: portrait) {
    #CONTACT .page-cover {
        min-height: 70vw;
        background-attachment: local;
        background-position-x: 70%;
    }
}

@media (max-width:675px) {
    #CONTACT .page-cover .cover-content > .cover-title {
        font-size: 2.5em;
    }

        #CONTACT .page-cover .cover-content > .cover-title.title-2 > svg {
            height: 40px;
        }

    #CONTACT .page-cover .cover-content > .cover-desc {
        font-size: 1em;
    }
}

#CONTACT .bg-contact {
    /*    background-image: url(assets/bg-contact.jpg);*/
    /* background-attachment: fixed; */
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#CONTACT .page-contact {
    padding: 7vh 2vw;
    color: #333333;
    font-size: 1.1em;
}

    #CONTACT .page-contact .contact-title {
        font-size: 2em;
        line-height: 1;
        text-transform: uppercase;
    }

#CONTACT .ant-form {
    position: relative;
    padding: calc(7vh + 1em) 2vw 7vh;
    color: #ffffff;
    background-color: rgba(1, 12, 51, 0.8);
}

    #CONTACT .ant-form .form-title {
        font-size: 2em;
        line-height: 1;
        text-transform: uppercase;
    }

@media (max-width:991px) {
    #CONTACT .ant-form {
        background-color: transparent;
    }

        #CONTACT .ant-form:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 100vw;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
            background-color: rgba(1, 12, 51, 0.8);
            z-index: 0;
        }
}

#CONTACT .page-address {
    padding: 7vh 5vw;
    color: #ffffff;
    background-color: #ff6600;
    font-size: 1.1em;
}

    #CONTACT .page-address .address-title {
        font-size: 2em;
        line-height: 1;
        text-transform: uppercase;
    }
.head-back {
    display: flex;
    width: 100%;
    height: 10vw;
    background-color: rgba(34, 150, 132, 0.15);
    color: #229684;
    font-size: 1.5em;
    padding: 20px 0px 7px 10px;
}

.head-back>.icon-back {
    padding-right: 10px;
}

.head-back>.title-back::before {
    content: "";
    z-index: 0;
    position: absolute;
    display: block;
    top: -7px;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 1px;
    height: 35%;
    background-color: #229684;
}

.head-back>.title-back {
    padding-left: 12px;
    position: relative;
    font-size: 0.9em;
}

.head-back>.back-img {
    position: relative;
    /* bottom: 5px; */
}

.head-back>.back-img img {
    width: auto;
    height: 120px;
}

.head-back>.content-booking {
    z-index: 1;
    position: relative;
    /* width: 100%; */
    width: 70%;
    bottom: 20px;
    margin-left: auto;
    
}

.head-back>.content-booking>.booking-head {
    display: flex;
    justify-content: center;
}

.head-back>.content-booking .booking-title {
    display: block;
    margin: 0 auto;
    padding: 0.25em;
    min-width: 60%;
    text-align: center;
    font-size: 2.5vw;
    color: #239684;
    border-top: 1px solid #239684;
    border-bottom: 1px solid #239684;
}

.head-back>.content-booking .booking-slider>.slick-dots {
    bottom: 0;
    right: 0;
    display: flex !important;
    width: auto;
    padding: 1.5vw;
}

.head-back>.content-booking .booking-slider>.slick-dots>li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.head-back>.content-booking .booking-slider>.slick-dots>li>button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s;
}

.head-back>.content-booking .booking-slider>.slick-dots>li>button::before {
    content: none;
}

.head-back>.content-booking .booking-slider>.slick-dots>li:hover>button {
    opacity: 0.5;
}

.head-back>.content-booking .booking-slider>.slick-dots>li.slick-active>button {
    opacity: 1;
}

.head-back>.content-booking .booking-link {
    position: relative;
    display: block;
    height: 10vw;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.head-back>.content-booking .booking-link::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    opacity: 0;
    transition: opacity .3s;
}

.head-back>.content-booking .booking-link:hover::after {
    opacity: 0.3;
    cursor: pointer;
}

@media(max-width: 1200px) {
    .head-back>.back-img {
        bottom: 18px;
    }
    .head-back>.content-booking {
        z-index: 1;
        position: relative;
        width: 67%;
    }
}

@media(max-width: 992px) {
    .head-back {
        height: 13vw;
    }
    .head-back>.back-img {
        bottom: 15px;
    }
    .head-back>.content-booking .booking-link {
        height: 13vw;
    }
}

@media(max-width: 769px) {
    .head-back {
        height: 15vw;
    }
    .head-back>.back-img {
        bottom: 3px;
    }
    .head-back>.back-img img {
        height: 80px;
    }
    .head-back>.content-booking {
        width: 73%;
    }
    .head-back>.content-booking .booking-link {
        height: 15vw;
    }
}

@media(max-width: 585px) {
    .head-back>.title-back::before {
        content: "";
        z-index: 0;
        position: absolute;
        display: block;
        top: -7px;
        bottom: 20px;
        left: 0;
        right: 0;
        width: 1px;
        height: 90%;
        background-color: #229684;
    }
    .head-back>.back-img {
        bottom: 15px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}

@media(max-width: 510px) {
    .head-back>.back-img {
        bottom: 21px;
    }
}

@media(max-width: 415px) {
    .head-back>.title-back {
        font-size: 0.7em;
    }
    .head-back>.back-img {
        bottom: 12px;
    }
    .head-back>.back-img img {
        height: 50px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}

@media(max-width: 376px) {
    .head-back {
        margin-top: 9px;
    }
    .head-back>.back-img {
        bottom: 6px;
    }
    .head-back>.back-img img {
        height: 40px;
    }
    .head-back>.content-booking {
        width: 60%;
    }
}


.ibox {
    display: flex;
    padding: 0 3vw;
    align-items: center;
}

.ibox>.ibox-thumb {
    display: block;
    text-align: center;
}

.ibox>.ibox-info {
    display: block;
    padding: 15px 25px;
    flex-basis: 100%;
    overflow: hidden;
}

.ibox .box-cover {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #d4d9e2;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: background-size .3s;
}

.ibox .box-cover:hover {
    background-size: cover;
}

.ibox .box-title {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4;
    overflow: hidden;
    margin-bottom: 0.5em;
    color: #346460;
}

.ibox .box-detail {
    display: block;
    font-size: 1em;
    margin-bottom: 0.6em;
}

.ibox .box-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    transition: all .3s;
    color: #229684;
    font-size: 0.75em;
}

.ibox:hover .box-links {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

.ibox .box-links>li {
    padding: 5px 0;
    padding-right: 10px;
}

.ibox .box-links>li+li {
    padding-left: 10px;
    border-left: 1px solid #229684;
}

.ibox .box-links a {
    display: block;
    line-height: 1;
    color: #2078b0;
    text-decoration: none;
}

.ibox .box-links a:hover {
    color: #5c6773;
}

.ibox .box-tags{
    margin-top: 20px;
}

@media (max-width:600px) {
    .ibox {
        display: block;
    }
    .ibox>.ibox-info {
        padding: 0 0 10px;
    }
    .ibox .box-cover {
        display: inline-block;
    }
    .ibox .box-title {
        -webkit-line-clamp: unset;
        height: auto;
        font-size: 1.1em;
    }
    .ibox .box-links {
        margin-top: 10px;
    }
}


/* list */

.ibox-list {
    display: block;
    align-items: center;
}

.ibox-list>.ibox-img {
    display: block;
    text-align: center;
}

.ibox-list>.ibox-info {
    display: block;
    padding: 15px 0px;
    flex-basis: 100%;
    overflow: hidden;
}

.ibox-list .box-cover {
    display: block;
    width: 252px;
    height: 157px;
    background-color: #bbbbbb;
    /* background-image: url(../Images/home/example/news/news1.jpg); */
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #d4d9e2;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: background-size .3s;
}

.ibox-list .box-cover:hover {
    background-size: 120%;
}

.ibox-list .box-title {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    overflow: hidden;
    margin-bottom: 0.5em;
    color: #3da393;
}

.ibox-list .box-detail {
    display: block;
    font-size: 1em;
    margin-bottom: 0.6em;
}

.ibox-list .box-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    transition: all .3s;
    font-weight: bold;
    font-size: 0.75em;
}

.ibox-list:hover .box-links {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

.ibox-list .box-links>li {
    padding: 5px 0;
    padding-right: 10px;
}

.ibox-list .box-links>li+li {
    padding-left: 10px;
    /* border-left: 1px solid #229684; */
}

.ibox-list .box-links a {
    display: block;
    line-height: 1;
    color: #2078b0;
    text-decoration: none;
}

.ibox-list .box-links a:hover {
    color: #5c6773;
}

.ibox-list .box-tags{
    margin-top: 20px;
}

@media (max-width:600px) {
    .ibox-list {
        display: block;
    }
    .ibox-list>.ibox-info {
        padding: 0 0 10px;
        /* text-align: center; */
    }
    .ibox-list .box-cover {
        display: inline-block;
    }
    .ibox-list .box-title {
        -webkit-line-clamp: unset;
        height: auto;
        font-size: 1.1em;
    }
    .ibox-list .box-links {
        margin-top: 10px;
    }
}

/* panel */
#PAGE_HOME>.home-news>.news-panel>.panel-item {
    display: flex;
    margin-top: 1.5vw;
    height: 35vw;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5vw;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .left {
    flex-basis: 40%;
    max-width: 40%;
    position: relative;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item-content {
    margin-top: 1.5vw;
}


#PAGE_HOME > .home-news > .news-panel > .panel-item-content > .news-title {
    color: #346460;
    font-size: 1.2em;
    font-weight: 600;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item-transparent {
    display: flex;
    margin-top: 1.5vw;
    height: 16vw;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent .news-item {
    cursor: pointer;
    display: block;
    flex-basis: 23%;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item  .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item  .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item  .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .news-item .news-date, #PAGE_HOME>.home-news>.news-panel>.panel-item-transparent >.news-item .news-date {
    z-index: 2;
    position: absolute;
    bottom: 4%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item:hover::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item:hover::after {
    opacity: 0.25;
} */

#PAGE_HOME > .home-news > .news-panel > .panel-item  >  .news-item.news-primary, 
#PAGE_HOME > .home-news > .news-panel > .panel-item  >  .news-item.news-primary-x, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary {
    flex-basis: 40%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary > .news-cover, 
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x > .news-cover, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary > .news-title,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x > .news-title,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary .news-date,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x .news-date,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary   .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary:hover::before,
/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x:hover::before, */
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary:hover::after,
/* #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x:hover::after, */
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  > .news-item.news-primary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item:nth-of-type(2) {
    margin-left: 1%;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item:last-of-type {
    margin-right: 1%;
}

.ebook-popup {
    top: 0%;
}

.ebook-popup > .ant-modal-content {
    background-color: transparent !important;
    box-shadow: 0px 0px;
    top: 0%;
}

.ebook-popup > .ant-modal-content > .ant-modal-close {
    margin-top: 3em;
    margin-right: 2em;
}

/* .ebook-full >  .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
   } */

/* .ant-modal-body {
height: calc(100vh - 110px);
} */

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 38vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        flex-basis: 44%;
        height: 15vw;
        margin: 0 0 29px 30px;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 767px) {
    #PAGE_HOME>.home-news {
        padding: 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 80vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item   .news-item.news-primary-x,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent   .news-item.news-primary {
        flex-basis: 48%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .news-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
}

/* secondary */

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-basis: 60%;
    max-width: 60%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider{
    width: 100%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
    bottom: -7px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li {
    margin: 0 3px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li button:before {
    font-size: 10px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #37a08f;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .right-item {
    display: block;
    min-width: 50%;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-prev, #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-next {
    top: calc(22.5vw * 3 / 4 * 0.5);
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .scroll-panel{
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .scroll-panel .scrollarea-content {
    display: flex;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item {
    position: relative;
    /* flex-basis: calc(100% / 2);
    max-width: calc(100% / 2); */
    display: flex;
    flex-direction: column;
    min-width: 45%;
    cursor: pointer;
}

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-cover {
        z-index: 0;
        position: absolute;
        top: 5%;
        bottom: 4.75em;
        left: 2.5%;
        right: 2.5%;
        background-color: #bbbbbb;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
    z-index: 2;
    position: absolute;
    /* top: 67.5%; */
    bottom: 2.5em;
    left: 2.5%;
    right: 3.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 0.9em;
    line-height: 1.3;
    height: 2.5em;
    overflow: hidden;
    color: #3da393;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item .secondary-item .news-date, #PAGE_HOME>.home-news>.news-panel>.panel-item-transparent .secondary-item .news-date {
    z-index: 2;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    font-size: 0.75em;
    font-weight: bold;
    color: #043400;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::before{
    z-index: 2;
    position: absolute;
    right: 0.66em;
    bottom: 5%;
    font-family: IcoFont;
    content: "\eaca";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1em;
    height: 1.1em;
    font-size: 1.5em;
    color: #37a08f;
    border: 1px solid #37a08f;
    border-radius: 50%;
    transition: color .3s, background-color .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 4.75em;
    left: 2.5%;
    right: 2.5%;
    background-color: rgba(5, 53, 2, 1);
    opacity: 0;
    transition: opacity .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:hover::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:hover::before {
    color: #ffffff;
    background-color: #37a08f;
}

/* #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:hover::after,
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:hover::after {
    opacity: 0.25;
} */

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
    /* flex-basis: 45%; */
    height: 14vw;
    margin: 0 0 15px 30px;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary  .news-cover,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x  .news-cover,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary  .news-title,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x  .news-title,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-title {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary  .news-date,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x  .news-date,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary  .news-date {
    left: 5%;
    color: #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary::before {
    color: #ffffff;
    border: 1px solid #ffffff;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary::after, 
#PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(130, 154, 129, 0) 50%, rgba(5, 53, 2, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#053502', GradientType=0);
    opacity: 1;
    transition: background .3s;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary:hover::before,
#PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x:hover::before,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary:hover::before {
    background-color: #37a08f;
    border: 1px solid #37a08f;
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary:hover::after,
#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x:hover::after,
 #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary:hover::after {
    background: rgba(5, 53, 2, 0.25);
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:nth-of-type(2), #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:nth-of-type(2) {
    /* margin-left: 1%; */
}

#PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item:last-of-type, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item:last-of-type {
    /* margin-right: 1%; */
}

.flowpaper_bttnFullscreen{
    display: none;
}

@media(max-width: 1199px) {
    #PAGE_HOME>.home-news>.news-panel {
        padding: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        flex-basis: 44%;
        height: 15vw;
        margin: 0 0 29px 30px;
    }
}

@media(max-width: 991px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
        bottom: -5px;
    }

    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.9em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        height: 40vw;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item  .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        right: 2.5%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item::before, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::before {
        font-size: 1.2em;
    }
}

@media(max-width: 769px) {
    #PAGE_HOME>.home-news {
        padding: 3vw;
    }
    #PAGE_HOME>.home-news>.news-title {
        font-size: 0.8em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 70vw;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-head {
        font-size: 0.8em;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-item > .left {
        flex-basis: 100%;
        max-width: 100%;
        position: relative;
    }

    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right {
        flex-basis: 100%;
        min-width: 100%;
        position: relative;
        margin-top: 10px;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item.news-primary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .news-item.news-primary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .news-item.news-primary {
        height: 20vw;
    }



    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right .slick-slider .slick-dots {
        bottom: -10px;
    }
    
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item,
    #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent  .secondary-item.news-secondary {
        flex-basis: 60%;
        margin: 1%;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item > .news-cover, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-cover {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item > .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        bottom: 2em;
    }
    #PAGE_HOME > .home-news > .news-panel > .panel-item > .secondary-item::after, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item::after {
        top: 0;
        bottom: 4.35em;
        left: 0;
        right: 0;
    }
}

@media(max-width: 576px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 75vw;
    }
}
@media(max-width: 415px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 90vw;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item .news-title, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item > .news-title {
        font-size: 0.75em;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary .news-title,
    #PAGE_HOME > .home-news > .news-panel > .panel-item .secondary-item.news-secondary-x .news-title,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary > .news-title {
        top: 10%;
    }

    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary,
    #PAGE_HOME > .home-news > .news-panel > .panel-item  .secondary-item.news-secondary-x,
     #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent > .secondary-item.news-secondary {
        height: 20vw;
    }
}

@media(max-width: 376px) {
    #PAGE_HOME > .home-news > .news-panel > .panel-item, #PAGE_HOME > .home-news > .news-panel > .panel-item-transparent {
        flex-wrap: wrap;
        padding: 1%;
        height: 95vw;
    }
}

/* knowledge */
#KNOWLEDGE {
    background-color: #ffffff;
    background-position-x: top;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
@media (max-width: 1199px) {
    #KNOWLEDGE {
        padding: 5vh 15px;
    }
}

.iknowledge {
    display: flex;
    padding: 15px 0;
    width: 100%;
}

.iknowledge+.iknowledge {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.iknowledge>.iknowledge-info {
    display: block;
    padding: 6px 0px;
    flex-basis: 100%;
    overflow: hidden;
}

.iknowledge .knowledge-flag {
    display: inline-block;
    margin-bottom: 0px;
    padding: 0px 20px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    color: #346460;
}

.iknowledge .knowledge-level {
    display: block;
    padding: 5px 20px;
    font-size: 1.2em;
    line-height: 1;
    color: #2e95ff;
}

.iknowledge .knowledge-title {
    display: block;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1;
}

.iknowledge .knowledge-desc {
    display: block;
    margin-top: 10px;
    line-height: 1.1;
}

.iknowledge .knowledge-tags {
    display: block;
    margin-top: 5px;
    line-height: 1;
}
.iknowledge .knowledge-tags .knowledge-ex {
    padding: 7px 20px;
    display: grid;
}
.iknowledge .knowledge-flag+.knowledge-tags {
    margin-top: 15px;
}
.head-title {
    font-weight: 500;
}
.text-title {
    color: #249d8a;
    font-size: 1.2em;
    margin-top: 3px;
}

@media (max-width:575px) {
    .iknowledge {
        display: block;
    }
    .iknowledge>.iknowledge-info {
        padding: 20px 0;
        text-align: center;
    }
    .iknowledge .knowledge-title {
        font-size: 1.1em;
    }
    .iknowledge .knowledge-links {
        margin-left: 0;
        display: inline-flex;
    }
    .iknowledge .knowledge-tags,
    .iknowledge .knowledge-links {
        margin-top: 15px;
    }

    .iknowledge .knowledge-tags .knowledge-interview {
        display: inline-block;
    }
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
    display: block;
    position: absolute;
    width: 100%;
    bottom: -5px;
    margin: 0 25px;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-1 {
    display: inline-block;
    margin: 1px;
    text-align: left;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
    position: relative;
    display: inline-block;
    margin: 1px;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    left: 87%;
    cursor: pointer;
}

#PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
    position: relative;
    display: inline-block;
    margin: 1px;
    font-weight: normal;
    font-size: 0.8em;
    color: #ffffff;
    background-color: #37a08f;
    left: 97%;
    cursor: pointer;
}

@media(max-width: 768px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
        bottom: 0px;
        margin: 0px;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 84%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 93%;
    }
}

@media(max-width: 576px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 89%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 91%;
    }
}

@media(max-width: 415px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 70%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 87%;
    }
}

@media(max-width: 376px) {
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags {
        margin: 0px;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-2 {
        left: 67%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .button-badge-3 {
        left: 86%;
    }
    #PAGE_HOME>.home-news>.news-panel>.panel-item > .right >.button-tags .ant-pagination-item {
        min-width: 30px;
        height: 30px;
    }
}
#REPORT .Summary_Complaint_Report .ant-table-summary {
    font-weight: 500;
}

#REPORT .Summary_Complaint_Report .ant-table-thead > tr > th {
    text-align: center
}

#REPORT .ant-total {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
}



/*data*/
/*group1*/
/*#REPORT .Summary_Complaint_Report td.summary-in-group-1, th.cell-in-group-month-1 {
    background-color: #bae7ff !important;
}
*/




/*-----------*/





/*first column*/
#REPORT .ant-table-summary .ant-total {
    background-color: #e6ffe5 !important;
    font-weight: 500;
    border-bottom: 1px solid #000000;
}

#REPORT .ant-table-tbody .ant-table-cell-fix-left-last {
    background-color: #eaeaea !important;
}

#REPORT .ant-impress-the-website {
    background-color: red !important;
}


#REPORT .ant-table-summary .summary-in-group-1 {
    background-color: #bae7ff !important;
    border-bottom: 1px solid #000000;
}

#REPORT .ant-table-summary .summary-in-group-2 {
    background-color: #bae7ff !important;
    border-bottom: 1px solid #000000;
}
/*-----------*/

#REPORT td {
    border: 1px solid #000000;
    padding: 8px;
}

#REPORT th {
    border: 1px solid #000000;
    padding: 8px;
}


